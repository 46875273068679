import { KeyboardVerticalLayoutMap } from '../../KeyboardVertical.types';
import {
  ALPHANUMERIC_SYMBOL,
  SPECIAL_SYMBOL,
  DIACRITICAL_SYMBOL,
  buildKeysRow,
} from '~/components/KeyboardTemplate/buildKeysRow';
import type {
  IKeyboardTemplateKey,
  KeyboardTemplate,
  LetterCase,
} from '~/components/KeyboardTemplate/KeyboardTemplate.types';

type GenerateBottomKeys = (param: {
  keyboardLayout: KeyboardVerticalLayoutMap;
  letterCase: LetterCase;
  toggleSpecialLayout: () => void;
  toggleDiacriticalLayout: () => void;
}) => KeyboardTemplate;

type ControlsMap = Record<KeyboardVerticalLayoutMap, IKeyboardTemplateKey[][]>;

const ALPHANUMERIC_STATE = '-_';
const DIACRITICAL_STATE = 'úûü';

export const generateBottomKeys: GenerateBottomKeys = (params) => {
  const {
    keyboardLayout,
    letterCase,
    toggleSpecialLayout,
    toggleDiacriticalLayout,
  } = params;

  const controlsMapState: ControlsMap = {
    alphanumeric: [
      [
        ...buildKeysRow(ALPHANUMERIC_STATE, letterCase),
        {
          symbol:
            keyboardLayout === KeyboardVerticalLayoutMap.Special
              ? ALPHANUMERIC_SYMBOL
              : SPECIAL_SYMBOL,
          columns: 2,
          customAction: toggleSpecialLayout,
        },
        {
          symbol: DIACRITICAL_SYMBOL,
          columns: 2,
          customAction: toggleDiacriticalLayout,
        },
      ],
    ],
    diacritical: [
      [
        ...buildKeysRow(DIACRITICAL_STATE, letterCase),
        {
          symbol:
            keyboardLayout === KeyboardVerticalLayoutMap.Diacritical
              ? ALPHANUMERIC_SYMBOL
              : DIACRITICAL_SYMBOL,
          columns: 1.5,
          customAction: toggleDiacriticalLayout,
        },
        {
          symbol: SPECIAL_SYMBOL,
          columns: 1.5,
          customAction: toggleSpecialLayout,
        },
      ],
    ],
    special: [
      [
        {
          symbol:
            keyboardLayout === KeyboardVerticalLayoutMap.Special
              ? ALPHANUMERIC_SYMBOL
              : SPECIAL_SYMBOL,
          columns: 3,
          customAction: toggleSpecialLayout,
        },
        {
          symbol:
            keyboardLayout === KeyboardVerticalLayoutMap.Diacritical
              ? ALPHANUMERIC_SYMBOL
              : DIACRITICAL_SYMBOL,
          columns: 3,
          customAction: toggleDiacriticalLayout,
        },
      ],
    ],
  };

  return controlsMapState[keyboardLayout];
};
