import { useCallback, useRef } from 'react';
import {
  ScrollView,
  Platform,
  LayoutChangeEvent,
  Dimensions,
  UIManager,
  findNodeHandle,
  FlatList,
} from 'react-native';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { LayoutObject } from '@noriginmedia/react-spatial-navigation';

import { easeOutCubic } from '../utils/functions';

const { height: SCREEN_HEIGHT } = Dimensions.get('screen');

const isScrollView = (ref: ScrollView | FlatList | null): ref is ScrollView => {
  return Boolean((ref as any)?.scrollTo);
};

export const useScrollPostion = <T extends ScrollView | FlatList>() => {
  const scrollRef = useRef<T>(null);
  const visibleHeight = useRef(SCREEN_HEIGHT);

  const onLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    visibleHeight.current = nativeEvent.layout.height;
  };

  const handleElementFocus = useCallback((element: LayoutObject) => {
    if (Platform.OS === 'web') {
      scrollIntoView(element.node, {
        ease: easeOutCubic,
      });
      return;
    }

    const elementNode = findNodeHandle(element.node as any);
    const scrollNode = findNodeHandle(scrollRef.current);

    if (elementNode === null || scrollNode === null) {
      return;
    }

    UIManager.measureLayout(
      elementNode,
      scrollNode,
      () => {},
      (_left, top, _width, height) => {
        const offsetY = visibleHeight.current / 2 - height / 2;

        if (isScrollView(scrollRef.current)) {
          scrollRef.current.scrollTo({
            animated: true,
            x: 0,
            y: top - offsetY,
          });
        } else {
          scrollRef.current?.scrollToOffset({
            animated: true,
            offset: top - offsetY,
          });
        }
      }
    );
  }, []);

  const scrollToTop = useCallback(
    (element: LayoutObject) => {
      if (Platform.OS === 'web') {
        handleElementFocus(element);
        return;
      }

      if (isScrollView(scrollRef.current)) {
        scrollRef.current.scrollTo({
          animated: true,
          x: 0,
          y: 0,
        });
      } else {
        scrollRef.current?.scrollToOffset({
          animated: true,
          offset: 0,
        });
      }
    },
    [handleElementFocus]
  );

  return {
    scrollRef,
    scrollToTop,
    handleElementFocus,
    scrollProps: {
      onLayout,
      showsVerticalScrollIndicator: false,
    },
  };
};
