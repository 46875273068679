import styled from 'styled-components/native';

import { FocusableButton } from '~/components/FocusableButton/FocusableButton';
import { horizontalScale, verticalScale } from '~/utils/layout';
import { CheckIcon as Icon } from '~/assets/icons/CheckIcon';

import type { ISearchIconFilterProps } from './SearchFilterItem.types';

export const FilterItem = styled(FocusableButton).attrs(
  ({ theme: { colors } }) => ({
    borderColor: colors.text.default,
    focusedBorderColor: colors.text.default,
    textColor: colors.text.default,
    alignItems: 'flex-start',
  })
)<ISearchIconFilterProps>`
  margin-bottom: ${({ hasGap }) => verticalScale(hasGap ? 8 : 0)}px;
  border-radius: ${verticalScale(6)}px;
  border-width: 0px;
  padding: ${verticalScale(12)}px ${horizontalScale(12)}px;
  flex-direction: row;
`;

export const IconWrapper = styled.View`
  flex-shrink: 0;
  min-width: ${horizontalScale(24)}px;
  min-height: ${verticalScale(24)}px;
  margin-left: ${horizontalScale(18)}px;
`;

export const CheckIcon = styled(Icon).attrs(({ theme }) => ({
  fill: theme.colors.searchFilter.elementBackground,
  width: horizontalScale(24),
  height: verticalScale(24),
}))``;

export const Title = styled.Text<ISearchIconFilterProps>`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${verticalScale(18)}px;
  color: ${({ theme, isFocused }) =>
    isFocused ? theme.colors.text.active : theme.colors.text.default};
  flex-shrink: 1;
  text-align: left;
`;

export const Content = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
