import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { SVGIconProps } from '../icons.types';

export const TimerIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const {
    width = horizontalScale(16),
    height = verticalScale(16),
    fill = colors.text.default,
  } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 16 16">
      <Path
        d="M8 16C6.89333 16 5.85333 15.79 4.88 15.37C3.90667 14.95 3.06 14.38 2.34 13.66C1.62 12.94 1.05 12.0933 0.63 11.12C0.21 10.1467 0 9.10667 0 8C0 6.89333 0.21 5.85333 0.63 4.88C1.05 3.90667 1.62 3.06 2.34 2.34C3.06 1.62 3.90667 1.05 4.88 0.63C5.85333 0.21 6.89333 0 8 0C9.10667 0 10.1467 0.21 11.12 0.63C12.0933 1.05 12.94 1.62 13.66 2.34C14.38 3.06 14.95 3.90667 15.37 4.88C15.79 5.85333 16 6.89333 16 8C16 8.36 15.98 8.71333 15.94 9.06C15.9 9.40667 15.8333 9.74667 15.74 10.08C15.5533 9.86667 15.3367 9.68667 15.09 9.54C14.8433 9.39333 14.5733 9.29333 14.28 9.24C14.32 9.04 14.35 8.83667 14.37 8.63C14.39 8.42333 14.4 8.21333 14.4 8C14.4 6.21333 13.78 4.7 12.54 3.46C11.3 2.22 9.78667 1.6 8 1.6C6.21333 1.6 4.7 2.22 3.46 3.46C2.22 4.7 1.6 6.21333 1.6 8C1.6 9.78667 2.22 11.3 3.46 12.54C4.7 13.78 6.21333 14.4 8 14.4C8.68 14.4 9.33 14.3 9.95 14.1C10.57 13.9 11.14 13.62 11.66 13.26C11.82 13.4867 12.0167 13.6867 12.25 13.86C12.4833 14.0333 12.7333 14.1667 13 14.26C12.32 14.8067 11.5567 15.2333 10.71 15.54C9.86333 15.8467 8.96 16 8 16ZM13.8 12.8C13.52 12.8 13.2833 12.7033 13.09 12.51C12.8967 12.3167 12.8 12.08 12.8 11.8C12.8 11.52 12.8967 11.2833 13.09 11.09C13.2833 10.8967 13.52 10.8 13.8 10.8C14.08 10.8 14.3167 10.8967 14.51 11.09C14.7033 11.2833 14.8 11.52 14.8 11.8C14.8 12.08 14.7033 12.3167 14.51 12.51C14.3167 12.7033 14.08 12.8 13.8 12.8ZM10.64 11.76L7.2 8.32V4H8.8V7.68L11.76 10.64L10.64 11.76Z"
        fill={fill}
      />
    </Svg>
  );
};
