import { SpaceBarIcon } from '~/assets/icons/SpaceBarIcon';
import { BackspaceIcon } from '~/assets/icons/BackspaceIcon';
import type {
  KeyboardTemplate,
  LetterCase,
} from '~/components/KeyboardTemplate/KeyboardTemplate.types';
import { ShiftIcon } from '~/assets/icons/ShiftIcon';

type GenerateTopKeys = (param: {
  letterCase: LetterCase;
  toggleCase: () => void;
  handleSpace: () => void;
  handleBackspace: () => void;
}) => KeyboardTemplate;

export const generateTopKeys: GenerateTopKeys = (params) => {
  const { handleSpace, handleBackspace, toggleCase, letterCase } = params;

  return [
    [
      {
        symbol: ShiftIcon,
        columns: 2,
        customAction: toggleCase,
        selected: letterCase === 'upper',
      },
      {
        symbol: SpaceBarIcon,
        columns: 2,
        customAction: handleSpace,
      },
      {
        symbol: BackspaceIcon,
        columns: 2,
        customAction: handleBackspace,
      },
    ],
  ];
};
