import React, { useCallback, useRef } from 'react';
import { FlatList, ListRenderItemInfo } from 'react-native';

import { FilterBadge } from '~/components/FilterBadge/FilterBadge';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';
import type { Query } from '~/services/searchPrograms/searchProgramsApi.types';
import { horizontalScale } from '~/utils/layout';

import {
  Container,
  LinearGradientStart,
  LinearGradientEnd,
  AutoCompleteList,
} from './SearchAutoCompleteList.styles';

import type { ISearchAutoCompleteList } from './SearchAutoCompleteList.types';

export const SearchAutoCompleteList = (props: ISearchAutoCompleteList) => {
  const { onPressFilter, suggestionOptions } = props;
  const suggestionListRef = useRef<FlatList>(null);

  const lastBadgeProps = useBlockFocusDirection('right');

  const handleFilterFocus = useCallback((index: number) => {
    suggestionListRef.current?.scrollToIndex({
      index,
      animated: true,
      viewOffset: horizontalScale(32),
    });
  }, []);

  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<Query>) => {
      const isLastBadge = suggestionOptions.length - 1 === index;
      return (
        <FilterBadge
          text={item.query}
          handlePress={() => onPressFilter(item.id)}
          onBecameFocused={() => handleFilterFocus(index)}
          hasGap={!isLastBadge}
          {...(isLastBadge ? lastBadgeProps : {})}
        />
      );
    },
    [onPressFilter, suggestionOptions, handleFilterFocus, lastBadgeProps]
  );

  return (
    <Container>
      <LinearGradientStart />
      <AutoCompleteList
        ref={suggestionListRef}
        keyExtractor={keyExtractor}
        data={suggestionOptions}
        renderItem={renderItem}
      />
      <LinearGradientEnd />
    </Container>
  );
};

const keyExtractor = (item: Query) => String(item.id);
