import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { SVGIconProps } from '../icons.types';

export const ChevronIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const {
    width = horizontalScale(14),
    height = verticalScale(7),
    fill = colors.text.default,
  } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 14 8" fill="none">
      <Path
        d="M6.99524 5.70324L1.56885 0.276856C1.38428 0.0922854 1.16895 0.00307604 0.922854 0.0092284C0.67676 0.0153808 0.461427 0.110742 0.276856 0.295313C0.0922854 0.479884 0 0.695217 0 0.941311C0 1.18741 0.0922854 1.40274 0.276856 1.58731L5.94318 7.27209C6.09084 7.41975 6.25695 7.53049 6.44152 7.60432C6.62609 7.67815 6.81067 7.71506 6.99524 7.71506C7.17981 7.71506 7.36438 7.67815 7.54895 7.60432C7.73352 7.53049 7.89963 7.41975 8.04729 7.27209L13.7321 1.58731C13.9166 1.40274 14.0059 1.18433 13.9997 0.932083C13.9936 0.679836 13.8982 0.461427 13.7136 0.276856C13.529 0.0922854 13.3137 0 13.0676 0C12.8215 0 12.6062 0.0922854 12.4216 0.276856L6.99524 5.70324Z"
        fill={fill}
      />
    </Svg>
  );
};
