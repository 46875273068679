import React, { useRef, useCallback, memo } from 'react';
import { FlatList, ListRenderItemInfo } from 'react-native';
import { useAtomValue } from 'jotai';
import {
  FocusableElement,
  withFocusable,
} from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';

import { FilterBadge } from '~/components/FilterBadge/FilterBadge';
import { Divider } from '../Divider/Divider';
import { SearchInput } from '../SearchInput/SearchInput';
import { useSearchFilters } from '../../hooks/useSearchFilters';
import { searchAtoms } from '../../atoms/searchAtoms';
import type { FilterOption } from '~/services/searchPrograms/searchProgramsApi.types';
import { horizontalScale } from '~/utils/layout';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';
import { SearchResetButton } from '../SearchResetButton/SearchResetButton';
import type { FocusableButtonRef } from '~/components/FocusableButton/FocusableButton.types';
import { isNativeButton } from '~/components/FocusableButton/FocusableButton';

import {
  Container,
  CloseIcon,
  BadgeListContainer,
  FilterBadgeList,
  LinearGradientEnd,
  LinearGradientStart,
  Row,
} from './SearchWithFilters.styles';

export const SearchWithFiltersComponent = memo<FocusableElement>((props) => {
  const { setFocus } = props;
  const searchString = useAtomValue(searchAtoms.searchString);
  const { t } = useTranslation();
  const { selectedFilterOptions, onClearFilter, onClearAllFilters } =
    useSearchFilters();

  const filterListRef = useRef<FlatList>(null);
  const itemRefs = useRef<FocusableButtonRef[]>([]);

  const badgesProps = useBlockFocusDirection('up');
  const resetButtonProps = useBlockFocusDirection('right');

  const handleClearFilters = useCallback(() => {
    setFocus('search-keyboard');
    onClearAllFilters();
  }, [onClearAllFilters, setFocus]);

  const handleFilterFocus = useCallback((index: number) => {
    filterListRef.current?.scrollToIndex({
      index,
      animated: true,
      viewOffset: horizontalScale(32),
    });
  }, []);

  const handleRemoveFilter = useCallback(
    (categoryId: string, id: number, index: number) => {
      const targetRef =
        itemRefs.current[index - 1] || itemRefs.current[index + 1];

      itemRefs.current.splice(index, 1);

      if (isNativeButton(targetRef)) {
        targetRef.setNativeProps({ hasTVPreferredFocus: true });
      } else if (selectedFilterOptions.length === 1) {
        setFocus('search-keyboard');
      }
      onClearFilter(categoryId, id);
    },
    [onClearFilter, setFocus, selectedFilterOptions]
  );

  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<FilterOption>) => {
      return (
        <FilterBadge
          innerRef={(ref) => {
            if (ref) {
              itemRefs.current[index] = ref;
            }
          }}
          text={item.value}
          handlePress={() =>
            handleRemoveFilter(item.categoryId, item.id, index)
          }
          hasGap={selectedFilterOptions.length - 1 !== index}
          onBecameFocused={() => handleFilterFocus(index)}
          rightIcon={CloseIcon}
          {...badgesProps}
        />
      );
    },
    [selectedFilterOptions, handleRemoveFilter, handleFilterFocus, badgesProps]
  );

  const renderListFooter = useCallback(
    () => (
      <Row>
        <Divider />
        <SearchResetButton
          text={t('search.clear_search', 'Limpar Busca')}
          onPress={handleClearFilters}
          onEnterPress={handleClearFilters}
          {...resetButtonProps}
        />
      </Row>
    ),
    [handleClearFilters, resetButtonProps, t]
  );

  return (
    <Container>
      {searchString.length > 0 && (
        <Row>
          <SearchInput text={searchString} />
          <Divider hasLinearGradient />
        </Row>
      )}

      {selectedFilterOptions.length > 0 && (
        <BadgeListContainer>
          <LinearGradientStart />
          <FilterBadgeList
            ref={filterListRef}
            data={selectedFilterOptions}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            horizontal
            showsHorizontalScrollIndicator={false}
            ListFooterComponent={renderListFooter}
          />
          <LinearGradientEnd />
        </BadgeListContainer>
      )}
    </Container>
  );
});

const keyExtractor = (item: FilterOption) => String(item.value);

export const SearchWithFilters = withFocusable()(SearchWithFiltersComponent);
