import type {
  FilterCategories,
  SegmentFilters,
} from '~/services/searchPrograms/searchProgramsApi.types';

const categoryNameMap: Record<string, string> = {
  levels: 'intensity',
  objectives: 'goals',
  durationTime: 'time',
  targets: 'equipments',
};

export const formatFilterCategories = (
  segmentFilters: SegmentFilters
): FilterCategories[] => {
  const filterCategories: FilterCategories[] = Object.keys(segmentFilters).map(
    (categoryName) => {
      const options = segmentFilters[categoryName];
      const category = {
        id: categoryName,
        name: categoryNameMap[categoryName] ?? categoryName,
        options,
      };
      return category;
    }
  );

  return filterCategories;
};
