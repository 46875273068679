import i18next from 'i18next';

export const translateCategoryName = (category: string) => {
  switch (category) {
    case 'intensity':
      return i18next.t('search.intensity', 'Intensidade');
    case 'goals':
      return i18next.t('search.goals', 'Objetivo');
    case 'time':
      return i18next.t('search.time', 'Duração');
    case 'equipments':
      return i18next.t('search.equipments', 'Equipamentos');
    case 'instructors':
      return i18next.t('search.instructors', 'Treinadores');
    case 'modality':
      return i18next.t('search.modality', 'Modalidade');
    case 'gender':
      return i18next.t('search.gender', 'Gênero');
    default:
      return category;
  }
};
