import { ShiftIcon } from '~/assets/icons/ShiftIcon';
import { buildKeysRow } from '~/components/KeyboardTemplate/buildKeysRow';
import type {
  KeyboardTemplate,
  LetterCase,
} from '~/components/KeyboardTemplate/KeyboardTemplate.types';

const NUMBERS = '1234567890';
const DIACRITICS_1 = 'àáâãäåæçèé';
const DIACRITICS_2 = 'êëìíîïñòóô';
const DIACRITICS_3 = 'õöøœ';
const DIACRITICS_4 = 'ùúûü';

type GenerateDiacriticalKeys = (param: {
  letterCase: LetterCase;
  toggleCase: () => void;
}) => KeyboardTemplate;

export const generateDiacriticalKeys: GenerateDiacriticalKeys = (params) => {
  const { letterCase, toggleCase } = params;

  return [
    buildKeysRow(NUMBERS, letterCase),
    buildKeysRow(DIACRITICS_1, letterCase),
    buildKeysRow(DIACRITICS_2, letterCase),
    [
      {
        symbol: ShiftIcon,
        customAction: toggleCase,
        selected: letterCase === 'upper',
      },
      ...buildKeysRow(DIACRITICS_3, letterCase),
      { symbol: letterCase === 'upper' ? 'ẞ' : 'ß' },
      ...buildKeysRow(DIACRITICS_4, letterCase),
    ],
  ];
};
