import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';

import { searchAtoms } from '../atoms/searchAtoms';
import type {
  FilterOption,
  Segment,
} from '~/services/searchPrograms/searchProgramsApi.types';

export const useSearchFilters = () => {
  const setSearchString = useSetAtom(searchAtoms.searchString);
  const [selectedFilters, setSelectedFilters] = useAtom(
    searchAtoms.selectedFilters
  );

  const selectedFilterOptions = useMemo(() => {
    const options: FilterOption[] = [];

    for (const categoryId in selectedFilters) {
      const segments = selectedFilters[categoryId];
      segments.forEach((segment) => {
        options.push({ ...segment, categoryId });
      });
    }

    return options;
  }, [selectedFilters]);

  const onPressOption = useCallback(
    (categoryId: string, option: Segment) => {
      setSelectedFilters((prevFilters) => {
        const categoryFilters = prevFilters[categoryId] || [];

        const isOptionAlreadySelected = categoryFilters.some(
          (filter) => filter.id === option.id
        );

        const updatedCategoryFilters = isOptionAlreadySelected
          ? categoryFilters.filter((filter) => filter.id !== option.id)
          : [...categoryFilters, option];
        return {
          ...prevFilters,
          [categoryId]: updatedCategoryFilters,
        };
      });
    },
    [setSelectedFilters]
  );

  const onClearFilter = useCallback(
    (categoryId: string, optionId: number) => {
      setSelectedFilters((prevFilters) => {
        const categoryFilters = prevFilters[categoryId] || [];
        const updatedCategoryFilters = categoryFilters.filter(
          (segment) => segment.id !== optionId
        );

        return {
          ...prevFilters,
          [categoryId]: updatedCategoryFilters,
        };
      });
    },
    [setSelectedFilters]
  );

  const onClearAllFilters = useCallback(() => {
    setSearchString('');
    setSelectedFilters({});
  }, [setSelectedFilters, setSearchString]);

  const activeFiltersCount = useMemo(() => {
    return Object.keys(selectedFilters).reduce((acc, key) => {
      acc[key] = selectedFilters[key]?.length || 0;
      return acc;
    }, {} as { [categoryId: string]: number });
  }, [selectedFilters]);

  const hasSelectedFilters = useMemo(() => {
    for (const categoryId in selectedFilters) {
      const filter = selectedFilters[categoryId];
      if (filter.length > 0) {
        return true;
      }
    }
    return false;
  }, [selectedFilters]);

  return {
    selectedFilters,
    selectedFilterOptions,
    activeFiltersCount,
    hasSelectedFilters,
    onPressOption,
    onClearFilter,
    onClearAllFilters,
  };
};
