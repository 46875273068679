import React, { memo, useMemo } from 'react';

import { KeyboardTemplate } from '~/components/KeyboardTemplate/KeyboardTemplate';
import { IKeyboardLayoutLetterCase } from '../../KeyboardVertical.types';

import { generateDiacriticalKeys } from './generateDiacriticalKeys';

const DiacriticalLayoutComponent = (props: IKeyboardLayoutLetterCase) => {
  const { letterCase } = props;

  const template = useMemo(
    () => generateDiacriticalKeys({ letterCase }),
    [letterCase]
  );

  return <KeyboardTemplate {...props} template={template} hasGap />;
};

export const DiacriticalLayout = memo(DiacriticalLayoutComponent);
