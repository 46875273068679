import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import {
  FocusableElement,
  withFocusable,
} from '@noriginmedia/react-spatial-navigation';
import { useTranslation } from 'react-i18next';

import { searchAtoms } from '../../atoms/searchAtoms';
import { SearchInput } from '../SearchInput/SearchInput';
import { SearchAutoCompleteList } from '../SearchAutoCompleteList/SearchAutoCompleteList';
import { normalizeWithoutAccents } from '../../utils/normalizeWithoutAccents';
import type { Query } from '~/services/searchPrograms/searchProgramsApi.types';
import { Divider } from '../Divider/Divider';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';
import { SearchResetButton } from '../SearchResetButton/SearchResetButton';
import { inMemoryCache } from '../../utils/inMemoryCache';

import { Container } from './SearchAutoComplete.styles';

const MAX_SUGGESTIONS = 10;
const FOCUS_BUTTON = 'reset-search-button';

export const SearchAutoCompleteComponent = memo<FocusableElement>((props) => {
  const { setFocus } = props;
  const { t } = useTranslation();
  const [searchString, setSearchString] = useAtom(searchAtoms.searchString);
  const [hasSelectedSuggestion, setHasSelectedSuggestion] = useAtom(
    searchAtoms.hasSelectedSuggestion
  );

  const [suggestions] = useState<Query[]>(
    inMemoryCache.get('SUGGESTIONS') || []
  );

  const resetButtonProps = useBlockFocusDirection('right');

  const filteredOptions = useMemo(() => {
    const normalizedText = normalizeWithoutAccents(searchString);

    return suggestions
      .filter((suggestion) => {
        const normalizedQuery = normalizeWithoutAccents(suggestion.query);
        return (
          normalizedQuery !== normalizedText &&
          normalizedQuery.includes(normalizedText)
        );
      })
      .slice(0, MAX_SUGGESTIONS);
  }, [suggestions, searchString]);

  const onPressFilter = useCallback(
    (id: number) => {
      const suggestionFound = suggestions.find((query) => query.id === id);

      if (suggestionFound) {
        setSearchString(suggestionFound.query);
        setHasSelectedSuggestion(true);
      }
    },
    [suggestions, setSearchString, setHasSelectedSuggestion]
  );

  const resetSearch = () => {
    setHasSelectedSuggestion(false);
    setSearchString('');
    setFocus('search-keyboard');
  };

  useEffect(() => {
    if (hasSelectedSuggestion) {
      setFocus(FOCUS_BUTTON);
    }
  }, [setFocus, hasSelectedSuggestion]);

  return (
    <Container>
      <SearchInput text={searchString} />

      {hasSelectedSuggestion ? (
        <>
          <Divider />
          <SearchResetButton
            text={t('search.clear_search', 'Limpar Busca')}
            onEnterPress={resetSearch}
            onPress={resetSearch}
            hasTVPreferredFocus
            focusKey={FOCUS_BUTTON}
            {...resetButtonProps}
          />
        </>
      ) : (
        <>
          {filteredOptions.length > 0 && <Divider hasLinearGradient />}
          <SearchAutoCompleteList
            suggestionOptions={filteredOptions}
            onPressFilter={(queryId: number) => onPressFilter(queryId)}
          />
        </>
      )}
    </Container>
  );
});

export const SearchAutoComplete = withFocusable({ autoRestoreFocus: false })(
  SearchAutoCompleteComponent
);
