import { api } from '~/configs/api';

import {
  IRecentAndPopularSearches,
  ISearchProgram,
  ISearchProgramsParams,
  SearchSuggestions,
  SegmentFilters,
} from './searchProgramsApi.types';

export const fetchSearchSuggestions = async () => {
  const { data } = await api.get<SearchSuggestions>(
    '/filterprograms/filter/filters-values'
  );

  return data;
};

export const fetchRecentAndPopularSearches = async () => {
  const { data } = await api.get<IRecentAndPopularSearches>(
    '/filterprograms/loger/searches'
  );

  return data;
};

export const fetchFilters = async () => {
  const { data } = await api.get<SegmentFilters>(
    '/filterprograms/loger/v2/segments-filter'
  );

  return data;
};

export const fetchSearchProgramsByFilters = async (
  params: ISearchProgramsParams
) => {
  const { locale, searchParams, signal } = params;

  const { data } = await api.get<ISearchProgram[]>(
    `/filterprograms/filter/search-programs?locale=${locale}${
      searchParams ? '&' + searchParams : ''
    }`,
    { signal }
  );

  return data;
};
