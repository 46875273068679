import styled from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { SearchIcon as Icon } from '~/assets/icons/SearchIcon';
import type { SVGIconProps } from '~/assets/icons.types';

export const Container = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.Text`
  font-size: ${verticalScale(20)}px;
  color: ${({ theme }) => theme.colors.text.default};
  margin-left: ${horizontalScale(8)}px;
`;

export const SearchIcon = styled(Icon).attrs<SVGIconProps>(({ theme }) => ({
  width: horizontalScale(17),
  height: verticalScale(17),
  fill: theme.colors.search.itemBackground,
}))``;
