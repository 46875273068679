import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';

import { verticalScale, horizontalScale } from '~/utils/layout';
import { FocusableButton } from '../FocusableButton/FocusableButton';
import { LinearGradient } from '../LinearGradient/LinearGradient';

export const CARD_BORDER_RADIUS = verticalScale(8);

export const StyledFocusableButton = styled(FocusableButton).attrs(
  ({ theme: { colors } }) => ({
    focusedBorderColor: colors.spatialFocus,
  })
)`
  border-radius: ${CARD_BORDER_RADIUS}px;
  position: relative;
`;

export const CardBlocked = styled.View`
  flex: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.overlay};
  justify-content: center;
  align-items: center;
  border-radius: ${CARD_BORDER_RADIUS}px;
  z-index: 1;
`;

export const CardTag = styled.View`
  flex: 1;
  position: absolute;
  top: 0;
  right: ${horizontalScale(12)}px;
  background-color: ${({ theme }) => theme.colors.selectedItem};
  padding: ${verticalScale(4)}px ${horizontalScale(6)}px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 1;
`;

export const CardTagText = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(18)}px;
  font-family: ${({ theme }) => theme.fonts.medium};
`;

export const Tag = styled.View`
  background-color: ${({ theme }) => theme.colors.overlay};
  padding: ${verticalScale(12)}px;
  position: absolute;
  right: ${horizontalScale(16)}px;
  top: ${verticalScale(16)}px;
  align-items: center;
  justify-content: center;
  border-radius: ${verticalScale(4)}px;
`;

export const TagText = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(14)}px;
`;

export const FallbackCardTitle = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(26)}px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${verticalScale(24)}px;
  text-align: center;
`;

export const WatchedClass = styled.View`
  flex: 1;
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${({ theme }) => theme.colors.progress.elapsed};
  width: 100%;
  height: 8px;
`;

export const InstructorName = styled.Text`
  position: absolute;
  bottom: ${verticalScale(24)}px;
  left: ${horizontalScale(24)}px;
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(18)}px;
`;

export const StyledLinearGradient = styled(LinearGradient).attrs({
  colors: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.5)', 'rgba(0, 0, 0, 1)'],
})`
  position: absolute;
  bottom: -${StyleSheet.hairlineWidth}px;
  width: 100%;
  height: 50%;
  border-bottom-left-radius: ${CARD_BORDER_RADIUS}px;
  border-bottom-right-radius: ${CARD_BORDER_RADIUS}px;
`;
