import React from 'react';

import { Container, SearchIcon, Label } from './SearchInput.styles';

import type { ISearchInput } from './SearchInput.types';

export const SearchInput = (props: ISearchInput) => {
  const { text } = props;

  return (
    <Container>
      <SearchIcon />
      <Label>{text}</Label>
    </Container>
  );
};
