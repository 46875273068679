import React, { memo, useMemo, useCallback } from 'react';

import { KeyboardTemplate } from '~/components/KeyboardTemplate/KeyboardTemplate';
import { KeyboardHorizontalLayoutMap } from '../../KeyboardHorizontal.types';

import { generateEmailKeys } from './generateEmailKeys';

import type { IKeyboardControls } from '../../KeyboardHorizontal.types';

const EmailControlsComponent = (props: IKeyboardControls) => {
  const { onChangeText, keyboardLayout, updateLayout } = props;

  const toggleSpecialLayout = useCallback(() => {
    updateLayout((prevLayout) =>
      prevLayout !== KeyboardHorizontalLayoutMap.Special
        ? KeyboardHorizontalLayoutMap.Special
        : KeyboardHorizontalLayoutMap.Alphanumeric
    );
  }, [updateLayout]);

  const handleBackspace = useCallback(() => {
    onChangeText((prevText) => prevText.slice(0, -1));
  }, [onChangeText]);

  const template = useMemo(
    () =>
      generateEmailKeys({
        keyboardLayout,
        toggleSpecialLayout,
        handleBackspace,
      }),
    [handleBackspace, keyboardLayout, toggleSpecialLayout]
  );

  return <KeyboardTemplate {...props} template={template} />;
};

export const EmailControls = memo(EmailControlsComponent);
