import {
  ALPHANUMERIC_SYMBOL,
  buildKeysRow,
  SPECIAL_SYMBOL,
} from '~/components/KeyboardTemplate/buildKeysRow';
import type { KeyboardTemplate } from '~/components/KeyboardTemplate/KeyboardTemplate.types';
import { KeyboardVerticalLayoutMap } from '../../KeyboardVertical.types';

type GenerateEmailKeys = (param: {
  keyboardLayout: KeyboardVerticalLayoutMap;
  toggleSpecialLayout: () => void;
}) => KeyboardTemplate;

const CHARACTERS_1 = '._-@';

export const generateEmailKeys: GenerateEmailKeys = (params) => {
  const { keyboardLayout, toggleSpecialLayout } = params;

  return [
    [
      ...buildKeysRow(CHARACTERS_1),
      {
        symbol:
          keyboardLayout === KeyboardVerticalLayoutMap.Special
            ? ALPHANUMERIC_SYMBOL
            : SPECIAL_SYMBOL,
        columns: 2,
        customAction: toggleSpecialLayout,
      },
    ],
    [
      { symbol: '.com', columns: 2 },
      { symbol: '.br', columns: 1 },
      {
        symbol: '@gmail.com',
        columns: 3,
      },
    ],
    [
      {
        symbol: '@hotmail.com',
        columns: 3,
      },
      {
        symbol: '@yahoo.com',
        columns: 3,
      },
    ],
  ];
};
