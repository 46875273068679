import { LayoutObject } from '@noriginmedia/react-spatial-navigation';
import { Dispatch, SetStateAction } from 'react';

import type {
  IKeyboard,
  IKeyboardTemplate,
  LetterCase,
} from '../KeyboardTemplate/KeyboardTemplate.types';

export enum KeyboardVerticalLayoutMap {
  Alphanumeric = 'alphanumeric',
  Special = 'special',
  Diacritical = 'diacritical',
}

export interface IKeyboardVertical
  extends Pick<IKeyboard, 'onChangeText' | 'hasTVPreferredFocus' | 'focusKey'> {
  type?: 'default' | 'email';
  blockFocusUp?: boolean;
  onBecameFocused?: (element: LayoutObject) => void;
}

export interface IKeyboardVerticalLayout
  extends Omit<IKeyboardTemplate, 'template' | 'hasGap'> {}

export interface IKeyboardVerticalControls extends IKeyboardVerticalLayout {
  updateLayout: Dispatch<SetStateAction<KeyboardVerticalLayoutMap>>;
  keyboardLayout: KeyboardVerticalLayoutMap;
}

export interface IKeyboardLayoutLetterCase extends IKeyboardVerticalLayout {
  letterCase: LetterCase;
}

export interface IKeyboardVerticalTopControls extends IKeyboardVerticalLayout {
  toggleCase: () => void;
  letterCase: LetterCase;
  blockFocusUp?: boolean;
}

export interface IKeyboardVerticalBottomControls
  extends IKeyboardVerticalControls {
  letterCase: LetterCase;
}
