import { buildKeysRow } from '~/components/KeyboardTemplate/buildKeysRow';

const SPECIALS_1 = "‘“^~`'";
const SPECIALS_2 = '!?@$%&';
const SPECIALS_3 = '#*-_+=';
const SPECIALS_4 = '.,;:<>';

const SPECIALS_5 = '()[]{}';
const SPECIALS_6 = '\\|/ªº?';
const SPECIALS_7 = '¢€£¥¡¿';

export const generateSpecialKeys = () => {
  return [
    buildKeysRow(SPECIALS_1),
    buildKeysRow(SPECIALS_2),
    buildKeysRow(SPECIALS_3),
    buildKeysRow(SPECIALS_4),
    buildKeysRow(SPECIALS_5),
    buildKeysRow(SPECIALS_6),
    buildKeysRow(SPECIALS_7),
  ];
};
