import React, { memo, useCallback } from 'react';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { KeyboardVertical } from '~/components/KeyboardVertical/KeyboardVertical';
import { searchAtoms } from '../../atoms/searchAtoms';

import { FiltersTitle } from './SidebarKeyboard.styles';

import type { ISideBarKeyboard } from './SidebarKeyboard.types';

const FOCUS_KEY = 'search-keyboard';

export const SidebarKeyboard = memo<ISideBarKeyboard>((props) => {
  const { onBecameFocused } = props;

  const { t } = useTranslation();

  const setSearchString = useSetAtom(searchAtoms.searchString);
  const setHasSelectedSuggestion = useSetAtom(
    searchAtoms.hasSelectedSuggestion
  );

  const handleChangeText = useCallback(
    (update: (prevText: string) => string) => {
      setHasSelectedSuggestion(false);
      setSearchString((prevText) => update(prevText));
    },
    [setHasSelectedSuggestion, setSearchString]
  );

  return (
    <>
      <KeyboardVertical
        onChangeText={handleChangeText}
        focusKey={FOCUS_KEY}
        onBecameFocused={onBecameFocused}
        hasTVPreferredFocus
        blockFocusUp
      />
      <FiltersTitle>{t('search.filters', 'Filtros')}</FiltersTitle>
    </>
  );
});
