import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';

export const FiltersTitle = styled.Text`
  font-size: ${verticalScale(24)}px;
  color: ${({ theme }) => theme.colors.text.default};
  font-family: ${({ theme }) => theme.fonts.medium};
  margin-top: ${verticalScale(48)}px;
  margin-bottom: ${verticalScale(24)}px;
  margin-left: ${verticalScale(12)}px;
`;
