import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';
import { FocusableButton } from '../FocusableButton/FocusableButton';

export const Container = styled.View`
  overflow: hidden;
  background: transparent;
`;

export const ExpandableHeaderButton = styled(FocusableButton).attrs(
  ({ theme: { colors } }) => ({
    borderColor: colors.text.default,
    focusedBorderColor: colors.text.default,
    textColor: colors.text.default,
  })
)`
  border-radius: ${verticalScale(6)}px;
  border-width: 0px;
`;
