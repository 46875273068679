import { FlatList } from 'react-native';
import styled from 'styled-components/native';

import { LinearGradient } from '~/components/LinearGradient/LinearGradient';
import type { FilterOption } from '~/services/searchPrograms/searchProgramsApi.types';
import { CloseIcon as Close } from '~/assets/icons/CloseIcon';
import { horizontalScale, verticalScale } from '~/utils/layout';

export const Container = styled.View`
  flex-direction: row;
  align-items: center;
  min-height: ${verticalScale(42)}px;
`;

export const FilterBadgeList = styled(
  FlatList as new () => FlatList<FilterOption>
).attrs({
  horizontal: true,
  showsHorizontalScrollIndicator: false,
  contentContainerStyle: {
    alignItems: 'center',
  },
})`
  position: relative;
  max-width: ${horizontalScale(1280)}px;
  padding-right: ${horizontalScale(20)}px;
  padding-left: ${horizontalScale(10)}px;
`;

export const Row = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const BadgeListContainer = styled.View`
  flex-direction: row;
  align-items: center;
  max-width: 100%;
`;

export const CloseIcon = styled(Close).attrs({
  width: horizontalScale(14),
  height: verticalScale(14),
})``;

export const LinearGradientStart = styled(LinearGradient).attrs({
  colors: ['rgba(0, 0, 0, 1)', 'rgba(0, 0, 0, 0)'],
  start: { x: 1, y: 0.5 },
  end: { x: 0.2, y: 0.5 },
  direction: 'horizontal',
})`
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${horizontalScale(10)}px;
  z-index: 4;
`;

export const LinearGradientEnd = styled(LinearGradient).attrs({
  colors: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 1)'],
  start: { x: 2, y: 0.5 },
  end: { x: 2, y: 0.5 },
  direction: 'horizontal',
})`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${horizontalScale(20)}px;
  z-index: 4;
`;
