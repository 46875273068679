import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSetAtom } from 'jotai';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { FlatList, ListRenderItemInfo } from 'react-native';

import { FilterBadge } from '~/components/FilterBadge/FilterBadge';
import type { Query } from '~/services/searchPrograms/searchProgramsApi.types';
import { searchAtoms } from '../../atoms/searchAtoms';
import { fetchRecentAndPopularSearches } from '~/services/searchPrograms/searchProgramsApi';
import { Divider } from '../Divider/Divider';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';
import { inMemoryCache } from '../../utils/inMemoryCache';
import { horizontalScale } from '~/utils/layout';

import {
  LinearGradientStart,
  LinearGradientEnd,
  Container,
  TimerIcon,
  RecentAndPopularList,
  Row,
} from './SearchRecentAndPopular.styles';

const SearchRecentAndPopularComponent = () => {
  const [searchData, setSearchData] = useState<Query[]>(
    inMemoryCache.get('RECENT_AND_POPULAR') || []
  );
  const recentAndPopularListRef = useRef<FlatList>(null);

  const setSearchString = useSetAtom(searchAtoms.searchString);
  const setHasSelectedSuggestion = useSetAtom(
    searchAtoms.hasSelectedSuggestion
  );

  const badgesProps = useBlockFocusDirection('up');
  const lastBadgeProps = useBlockFocusDirection('right');

  useEffect(() => {
    const fetchSearches = async () => {
      const data = await fetchRecentAndPopularSearches();
      inMemoryCache.set('RECENT_AND_POPULAR', [
        ...data?.recentSearches,
        ...data?.popularSearches,
      ]);
      setSearchData([...data?.recentSearches, ...data?.popularSearches]);
    };

    fetchSearches();
  }, []);

  const handleFilterFocus = useCallback((index: number) => {
    recentAndPopularListRef.current?.scrollToIndex({
      index,
      animated: true,
      viewOffset: horizontalScale(32),
    });
  }, []);

  const handleSelectSuggestion = useCallback(
    (value: string) => {
      setHasSelectedSuggestion(true);
      setSearchString(value);
    },
    [setHasSelectedSuggestion, setSearchString]
  );

  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<Query>) => {
      const isRecent = index < 3;
      const isLastItem = index === searchData.length - 1;
      const isDividerIndex = index === 2;

      return (
        <Row>
          <FilterBadge
            key={`${item.id}_${index}`}
            text={item.query}
            leftIcon={isRecent ? TimerIcon : undefined}
            hasGap={!isDividerIndex}
            handlePress={() => handleSelectSuggestion(item.query)}
            {...(isLastItem ? lastBadgeProps : badgesProps)}
            onBecameFocused={() => handleFilterFocus(index)}
          />
          {isDividerIndex && <Divider />}
        </Row>
      );
    },
    [
      searchData,
      lastBadgeProps,
      badgesProps,
      handleSelectSuggestion,
      handleFilterFocus,
    ]
  );

  return (
    <Container>
      <LinearGradientStart />
      <RecentAndPopularList
        ref={recentAndPopularListRef}
        keyExtractor={keyExtractor}
        data={searchData}
        renderItem={renderItem}
      />
      <LinearGradientEnd />
    </Container>
  );
};

const keyExtractor = (item: Query, index: number) => `${item.id}_${index}`;

export const SearchRecentAndPopular = withFocusable()(
  SearchRecentAndPopularComponent
);
