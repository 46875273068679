import { FlatList } from 'react-native';
import styled from 'styled-components/native';

import { LinearGradient } from '~/components/LinearGradient/LinearGradient';
import type { Query } from '~/services/searchPrograms/searchProgramsApi.types';
import { horizontalScale } from '~/utils/layout';

export const Container = styled.View`
  flex-direction: row;
  align-items: center;
  max-width: 100%;
`;

export const AutoCompleteList = styled(
  FlatList as new () => FlatList<Query>
).attrs({
  horizontal: true,
  showsHorizontalScrollIndicator: false,
  contentContainerStyle: {
    paddingLeft: horizontalScale(12),
    paddingRight: horizontalScale(16),
  },
})`
  position: relative;
  max-width: ${horizontalScale(1280)}px;
`;

export const LinearGradientStart = styled(LinearGradient).attrs({
  colors: ['rgba(0, 0, 0, 1)', 'rgba(0, 0, 0, 0)'],
  start: { x: 2, y: 0.5 },
  end: { x: 2, y: 0.5 },
  direction: 'horizontal',
})`
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${horizontalScale(10)}px;
  z-index: 4;
`;

export const LinearGradientEnd = styled(LinearGradient).attrs({
  colors: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 1)'],
  start: { x: 2, y: 0.5 },
  end: { x: 2, y: 0.5 },
  direction: 'horizontal',
})`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${horizontalScale(20)}px;
  z-index: 4;
`;
