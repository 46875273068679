import { buildKeysRow } from '../../../KeyboardTemplate/buildKeysRow';
import type {
  KeyboardTemplate,
  LetterCase,
} from '~/components/KeyboardTemplate/KeyboardTemplate.types';

const LETTERS_1 = 'abcdef';
const LETTERS_2 = 'ghijkl';
const LETTERS_3 = 'mnopqr';
const LETTERS_4 = 'stuvwx';
const ALPHANUMERIC_1 = 'yz1234';
const NUMBERS = '567890';

type GenerateAlphanumericKeys = (param: {
  letterCase: LetterCase;
}) => KeyboardTemplate;

export const generateAlphanumericKeys: GenerateAlphanumericKeys = (params) => {
  const { letterCase } = params;
  return [
    buildKeysRow(LETTERS_1, letterCase),
    buildKeysRow(LETTERS_2, letterCase),
    buildKeysRow(LETTERS_3, letterCase),
    buildKeysRow(LETTERS_4, letterCase),
    buildKeysRow(ALPHANUMERIC_1, letterCase),
    buildKeysRow(NUMBERS, letterCase),
  ];
};
