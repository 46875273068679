import styled from 'styled-components/native';

import { ChevronIcon } from '~/assets/icons/ChevronIcon';
import { horizontalScale, verticalScale } from '~/utils/layout';
import { Expandable } from '~/components/Expandable/Expandable';

import type { IHeaderProps } from './SearchFilterAccordion.types';

export const ExpandableContainer = styled(Expandable)<IHeaderProps>`
  margin-bottom: ${({ hasGap }) => verticalScale(hasGap ? 16 : 0)}px;
`;

export const FilterAccordionHeader = styled.View`
  width: 100%;
  padding: ${verticalScale(12)}px ${horizontalScale(12)}px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LeftContent = styled.View`
  align-items: center;
  height: 100%;
  flex-direction: row;
`;

export const Title = styled.Text<IHeaderProps>`
  color: ${({ isFocused, isExpanded, theme }) => {
    if (isFocused) {
      return theme.colors.text.active;
    }
    return isExpanded
      ? theme.colors.text.default
      : theme.colors.searchFilter.closedText;
  }};
  font-size: ${verticalScale(20)}px;
  font-family: ${({ theme }) => theme.fonts.medium};
`;

export const Chevron = styled(ChevronIcon).attrs<IHeaderProps>(
  ({ isFocused, isExpanded, theme }) => ({
    fill: isFocused
      ? theme.colors.text.active
      : isExpanded
      ? theme.colors.text.default
      : theme.colors.searchFilter.closedText,
    width: horizontalScale(16),
    height: verticalScale(9),
  })
)<IHeaderProps>``;

export const IconWrapper = styled.View<IHeaderProps>`
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(-180deg)' : ' rotate(0deg)'};
`;

export const Badge = styled.Text`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: ${verticalScale(14)}px;
  background-color: ${({ theme }) =>
    theme.colors.searchFilter.elementBackground};
  border-radius: ${horizontalScale(999)}px;
  margin-left: ${horizontalScale(12)}px;
  padding: ${verticalScale(2)}px ${horizontalScale(8)}px;
  margin-top: ${verticalScale(2)}px;
`;

export const Content = styled.View`
  margin-top: ${verticalScale(8)}px;
`;
