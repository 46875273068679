import React, { useCallback } from 'react';
import { FlatList, ListRenderItemInfo } from 'react-native';

import type { FilterCategories } from '~/services/searchPrograms/searchProgramsApi.types';
import { SearchFilterAccordion } from '../SearchFilterAccordion/SearchFilterAccordion';
import { SearchFilterItem } from '../SearchFilterItem/SearchFilterItem';
import { translateCategoryName } from '../../utils/translateCategoryName';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';

import type { ISidebarFilterList } from './SidebarFilterList.types';

export const SidebarFilterList = (props: ISidebarFilterList) => {
  const {
    filterCategories,
    activeFiltersCount,
    onPressOption,
    selectedFilters,
    innerRef,
    handleElementFocus,
    listHeaderComponent,
    contentContainerStyle,
    onLayout,
  } = props;

  const lastItemProps = useBlockFocusDirection('down');

  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<FilterCategories>) => {
      const { id, name, options } = item;
      const isLastFilter = filterCategories.length - 1 === index;
      const selectedOptions = selectedFilters[id] || [];
      const translatedCategory = translateCategoryName(name);
      return (
        <SearchFilterAccordion
          title={translatedCategory}
          activeFiltersCount={activeFiltersCount[id] || 0}
          onBecameFocused={handleElementFocus}
          hasGap={!isLastFilter}
          triggerButtonProps={(expanded: boolean) =>
            isLastFilter && !expanded ? lastItemProps : {}
          }
        >
          {options.map((option, optionIndex) => {
            const isLastOption = options.length - 1 === optionIndex;
            const isSelected = selectedOptions.some(
              (selected) => selected.id === option.id
            );
            return (
              <SearchFilterItem
                key={option.id}
                title={option.value || ''}
                isSelected={isSelected}
                handlePress={() => onPressOption(id, option)}
                onBecameFocused={handleElementFocus}
                hasGap={options.length - 1 !== optionIndex}
                {...(isLastFilter && isLastOption ? { ...lastItemProps } : {})}
              />
            );
          })}
        </SearchFilterAccordion>
      );
    },
    [
      activeFiltersCount,
      onPressOption,
      handleElementFocus,
      selectedFilters,
      filterCategories,
      lastItemProps,
    ]
  );

  return (
    <FlatList
      ref={innerRef}
      data={filterCategories}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      onLayout={onLayout}
      ListHeaderComponent={listHeaderComponent}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={contentContainerStyle}
    />
  );
};

const keyExtractor = (item: FilterCategories) => item.id.toString();
