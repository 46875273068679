import React, {
  memo,
  useRef,
  useLayoutEffect,
  useEffect,
  useState,
} from 'react';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';

import { isNativeButton } from '../FocusableButton/FocusableButton';
import { Container } from '~/components/KeyboardTemplate/KeyboardTemplate.styles';

import { AlphanumericLayout } from './components/AlphanumericLayout/AlphanumericLayout';
import { SpecialLayout } from './components/SpecialLayout/SpecialLayout';
import { DiacriticalLayout } from './components/DiacriticalLayout/DiacriticalLayout';
import { DefaultControls } from './components/DefaultControls/DefaultControls';
import { EmailControls } from './components/EmailControls/EmailControls';
import { KeyboardHorizontalLayoutMap } from './KeyboardHorizontal.types';

import type { FocusableButtonRef } from '../FocusableButton/FocusableButton.types';
import type { IKeyboardHorizontal } from './KeyboardHorizontal.types';

export const KEYBOARD_COLUMNS = 10;

export const KeyboardComponent = (props: IKeyboardHorizontal) => {
  const { type = 'default', onChangeText, hasTVPreferredFocus } = props;

  const firstKeyRef = useRef<FocusableButtonRef>(null);

  const [keyboardLayout, setKeyboardLayout] =
    useState<KeyboardHorizontalLayoutMap>(
      KeyboardHorizontalLayoutMap.Alphanumeric
    );

  useLayoutEffect(() => {
    setKeyboardLayout(KeyboardHorizontalLayoutMap.Alphanumeric);
  }, [type]);

  useEffect(() => {
    if (!firstKeyRef.current) {
      return;
    }

    if (isNativeButton(firstKeyRef.current) && hasTVPreferredFocus) {
      firstKeyRef.current.setNativeProps({
        hasTVPreferredFocus: true,
      });
    }
  }, [hasTVPreferredFocus]);

  return (
    <Container keyboardColumns={KEYBOARD_COLUMNS}>
      {keyboardLayout === KeyboardHorizontalLayoutMap.Alphanumeric && (
        <AlphanumericLayout
          onChangeText={onChangeText}
          firstKeyRef={firstKeyRef}
          keyboardColumns={KEYBOARD_COLUMNS}
        />
      )}

      {keyboardLayout === KeyboardHorizontalLayoutMap.Special && (
        <SpecialLayout
          onChangeText={onChangeText}
          keyboardColumns={KEYBOARD_COLUMNS}
        />
      )}

      {keyboardLayout === KeyboardHorizontalLayoutMap.Diacritical && (
        <DiacriticalLayout
          onChangeText={onChangeText}
          keyboardColumns={KEYBOARD_COLUMNS}
        />
      )}

      {type === 'email' ? (
        <EmailControls
          onChangeText={onChangeText}
          keyboardLayout={keyboardLayout}
          updateLayout={setKeyboardLayout}
          keyboardColumns={KEYBOARD_COLUMNS}
        />
      ) : (
        <DefaultControls
          onChangeText={onChangeText}
          keyboardLayout={keyboardLayout}
          updateLayout={setKeyboardLayout}
          keyboardColumns={KEYBOARD_COLUMNS}
        />
      )}
    </Container>
  );
};

export const KeyboardHorizontal = withFocusable()(memo(KeyboardComponent));
