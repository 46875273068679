import styled from 'styled-components/native';

import { verticalScale } from '~/utils/layout';

export const Container = styled.View`
  flex-direction: column;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text.default};
  margin-bottom: ${verticalScale(24)}px;
  font-size: ${verticalScale(36)}px;
`;

export const FiltersContainer = styled.View`
  min-height: ${verticalScale(42)}px;
  margin-bottom: ${verticalScale(26)}px;
`;
