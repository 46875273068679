import React, { useEffect } from 'react';
import {
  withFocusable,
  FocusableElement,
} from '@noriginmedia/react-spatial-navigation';
import { useResetAtom } from 'jotai/utils';

import { useSpatialScreenFocus } from '~/hooks/useSpatialScreenFocus';
import { ScreenBackground } from '~/components/ScreenBackground/ScreenBackground';
import { fetchSearchSuggestions } from '~/services/searchPrograms/searchProgramsApi';

import { Sidebar } from './components/Sidebar/Sidebar';
import { ContentRow } from './Search.styles';
import { SearchContent } from './components/SearchContent/SearchContent';
import { formatSuggestions } from './utils/formatSuggestions';
import { inMemoryCache } from './utils/inMemoryCache';
import { searchAtoms } from './atoms/searchAtoms';

const FOCUS_KEY = 'search-keyboard';

const SearchComponent = (props: FocusableElement) => {
  const { stealFocus, updateAllSpatialLayouts, setFocus } = props;
  useSpatialScreenFocus({ stealFocus, updateAllSpatialLayouts });

  const resetSearchString = useResetAtom(searchAtoms.searchString);
  const resetSelectedFilters = useResetAtom(searchAtoms.selectedFilters);

  useEffect(() => {
    setFocus(FOCUS_KEY);
  }, [setFocus]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (inMemoryCache.get('SUGGESTIONS')) {
        return;
      }

      const suggestionsOptions = await fetchSearchSuggestions();
      const formattedSuggestions = formatSuggestions(suggestionsOptions);
      inMemoryCache.set('SUGGESTIONS', formattedSuggestions);
    };

    fetchSuggestions();
  }, []);

  useEffect(() => {
    return () => {
      resetSearchString();
      resetSelectedFilters();
    };
  }, [resetSearchString, resetSelectedFilters]);

  return (
    <ScreenBackground>
      <ContentRow>
        <Sidebar />
        <SearchContent />
      </ContentRow>
    </ScreenBackground>
  );
};

export const Search = withFocusable()(SearchComponent);
