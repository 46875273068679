import React, { memo } from 'react';
import { FlatList } from 'react-native';

import { GridCardList } from '~/components/GridCardList/GridCardList';
import type { IGridCardListItem } from '~/components/GridCardList/GridCardList.types';
import { useScrollPostion } from '~/hooks/useScrollPostion';
import {
  VERTICAL_SAFE_ZONE,
  HORIZONTAL_SAFE_ZONE,
  SEARCH_CARD_WIDTH,
} from '~/utils/constants';
import { horizontalScale } from '~/utils/layout';
import { ResultsNotFound } from '../ResultsNotFound/ResultsNotFound';
import { SearchGridSkeleton } from '../SearchGridSkeleton/SearchGridSkeleton';
import { SearchHeader } from '../SearchHeader/SearchHeader';

import type { ISearchGrid } from './SearchGrid.types';

export const SearchGrid = memo<ISearchGrid>((props) => {
  const { visiblePrograms, handleLoadMore, isLoading } = props;
  const { scrollRef, scrollProps, handleElementFocus } =
    useScrollPostion<FlatList<IGridCardListItem>>();

  return (
    <GridCardList
      {...scrollProps}
      innerRef={scrollRef}
      contentContainerStyle={{
        paddingVertical: VERTICAL_SAFE_ZONE,
        paddingLeft: horizontalScale(48),
        paddingRight: HORIZONTAL_SAFE_ZONE,
      }}
      cards={visiblePrograms}
      isLoading={isLoading}
      onBecameFocused={handleElementFocus}
      columns={3}
      columnSpacing={24}
      lazyContentLine={4}
      customCardWidth={SEARCH_CARD_WIDTH}
      ListHeaderComponent={
        <SearchHeader programsCount={visiblePrograms.length} />
      }
      ListEmptyComponent={
        isLoading ? (
          <SearchGridSkeleton columns={3} numberOfItems={9} />
        ) : (
          <ResultsNotFound />
        )
      }
      onEndReachedThreshold={0.3}
      onEndReached={handleLoadMore}
    />
  );
});
