import React, { memo, useMemo, useCallback } from 'react';

import { KeyboardTemplate } from '~/components/KeyboardTemplate/KeyboardTemplate';
import {
  IKeyboardVerticalControls,
  KeyboardVerticalLayoutMap,
} from '../../KeyboardVertical.types';

import { generateEmailKeys } from './generateEmailKeys';

const EmailControlsComponent = (props: IKeyboardVerticalControls) => {
  const { keyboardLayout, updateLayout } = props;

  const toggleSpecialLayout = useCallback(() => {
    updateLayout((prevLayout) =>
      prevLayout !== KeyboardVerticalLayoutMap.Special
        ? KeyboardVerticalLayoutMap.Special
        : KeyboardVerticalLayoutMap.Alphanumeric
    );
  }, [updateLayout]);

  const template = useMemo(
    () =>
      generateEmailKeys({
        keyboardLayout,
        toggleSpecialLayout,
      }),
    [keyboardLayout, toggleSpecialLayout]
  );

  return <KeyboardTemplate {...props} template={template} />;
};

export const EmailControls = memo(EmailControlsComponent);
