import React, { useEffect, useRef, useState } from 'react';
import { Animated, LayoutChangeEvent, StyleSheet, View } from 'react-native';

import { Container, ExpandableHeaderButton } from './Expandable.styles';

import type { IExpandable } from './Expandable.types';

export const Expandable = (props: IExpandable) => {
  const {
    header,
    contentContainerStyle,
    children,
    onBecameFocused,
    triggerButtonProps,
    ...rest
  } = props;
  const [expanded, setExpanded] = useState(false);
  const contentHeight = useRef(new Animated.Value(0)).current;
  const [measuredHeight, setMeasuredHeight] = useState(0);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    Animated.timing(contentHeight, {
      toValue: expanded ? measuredHeight : 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [expanded, measuredHeight, contentHeight]);

  const onLayoutContent = (e: LayoutChangeEvent) => {
    setMeasuredHeight(e.nativeEvent.layout.height);
  };

  return (
    <Container {...rest}>
      <ExpandableHeaderButton
        onBecameFocused={onBecameFocused}
        onPress={toggleExpand}
        onEnterPress={toggleExpand}
        {...(triggerButtonProps?.(expanded) ?? {})}
      >
        {(focused) => header(focused, expanded)}
      </ExpandableHeaderButton>
      <Animated.View
        style={[styles.contentContainer, { height: contentHeight }]}
      >
        <View
          onLayout={onLayoutContent}
          style={[styles.content, contentContainerStyle]}
        >
          {expanded && children}
        </View>
      </Animated.View>
    </Container>
  );
};

const styles = StyleSheet.create({
  content: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  contentContainer: {
    overflow: 'hidden',
  },
});
