import { FilterAtom } from '../atoms/searchAtoms';

export const formatToSearchParams = (
  searchString: string,
  selectedFilters: FilterAtom
): string => {
  const params = new URLSearchParams();

  if (searchString) {
    params.append('freeSearch', searchString);
  }

  for (const categoryId in selectedFilters) {
    const values = selectedFilters[categoryId];
    values.forEach((value) => {
      params.append(categoryId, value.id.toString());
    });
  }

  return params.toString();
};
