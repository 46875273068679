import React, { memo, useMemo, useCallback } from 'react';

import { KeyboardTemplate } from '~/components/KeyboardTemplate/KeyboardTemplate';
import {
  IKeyboardVerticalBottomControls,
  KeyboardVerticalLayoutMap,
} from '../../KeyboardVertical.types';

import { generateBottomKeys } from './generateBottomKeys';

const BottomControlsComponent = (props: IKeyboardVerticalBottomControls) => {
  const { keyboardLayout, letterCase, updateLayout } = props;

  const toggleSpecialLayout = useCallback(() => {
    updateLayout((prevLayout) =>
      prevLayout !== KeyboardVerticalLayoutMap.Special
        ? KeyboardVerticalLayoutMap.Special
        : KeyboardVerticalLayoutMap.Alphanumeric
    );
  }, [updateLayout]);

  const toggleDiacriticalLayout = useCallback(() => {
    updateLayout((prevLayout) =>
      prevLayout !== KeyboardVerticalLayoutMap.Diacritical
        ? KeyboardVerticalLayoutMap.Diacritical
        : KeyboardVerticalLayoutMap.Alphanumeric
    );
  }, [updateLayout]);

  const template = useMemo(
    () =>
      generateBottomKeys({
        keyboardLayout,
        letterCase,
        toggleSpecialLayout,
        toggleDiacriticalLayout,
      }),
    [keyboardLayout, letterCase, toggleSpecialLayout, toggleDiacriticalLayout]
  );

  return <KeyboardTemplate {...props} template={template} />;
};

export const BottomControls = memo(BottomControlsComponent);
