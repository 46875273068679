import React, { memo, useMemo } from 'react';

import { KeyboardTemplate } from '~/components/KeyboardTemplate/KeyboardTemplate';

import { generateAlphanumericKeys } from './generateAlphanumericKeys';

import type { IKeyboardLayoutLetterCase } from '../../KeyboardVertical.types';

const AlphanumericLayoutComponent = (props: IKeyboardLayoutLetterCase) => {
  const { letterCase } = props;

  const template = useMemo(
    () => generateAlphanumericKeys({ letterCase }),
    [letterCase]
  );

  return <KeyboardTemplate {...props} template={template} hasGap />;
};

export const AlphanumericLayout = memo(AlphanumericLayoutComponent);
