import React, { memo, useMemo, useCallback } from 'react';

import { KeyboardTemplate } from '~/components/KeyboardTemplate/KeyboardTemplate';
import { KeyboardHorizontalLayoutMap } from '../../KeyboardHorizontal.types';

import { generateDefaultKeys } from './generateDefaultKeys';

import type { IKeyboardControls } from '../../KeyboardHorizontal.types';

const DefaultControlsComponent = (props: IKeyboardControls) => {
  const { onChangeText, keyboardLayout, updateLayout } = props;

  const toggleSpecialLayout = useCallback(() => {
    updateLayout((prevLayout) =>
      prevLayout !== KeyboardHorizontalLayoutMap.Special
        ? KeyboardHorizontalLayoutMap.Special
        : KeyboardHorizontalLayoutMap.Alphanumeric
    );
  }, [updateLayout]);

  const toggleDiacriticalLayout = useCallback(() => {
    updateLayout((prevLayout) =>
      prevLayout !== KeyboardHorizontalLayoutMap.Diacritical
        ? KeyboardHorizontalLayoutMap.Diacritical
        : KeyboardHorizontalLayoutMap.Alphanumeric
    );
  }, [updateLayout]);

  const handleSpace = useCallback(() => {
    onChangeText((prevText) => prevText + ' ');
  }, [onChangeText]);

  const handleBackspace = useCallback(() => {
    onChangeText((prevText) => prevText.slice(0, -1));
  }, [onChangeText]);

  const template = useMemo(
    () =>
      generateDefaultKeys({
        keyboardLayout,
        toggleSpecialLayout,
        toggleDiacriticalLayout,
        handleSpace,
        handleBackspace,
      }),
    [
      keyboardLayout,
      toggleSpecialLayout,
      toggleDiacriticalLayout,
      handleSpace,
      handleBackspace,
    ]
  );

  return <KeyboardTemplate {...props} template={template} />;
};

export const DefaultControls = memo(DefaultControlsComponent);
