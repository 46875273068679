import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import type { Segment } from '~/services/searchPrograms/searchProgramsApi.types';

export type FilterAtom = {
  [categoryId: string]: Segment[];
};

const searchString = atomWithReset<string>('');
const selectedFilters = atomWithReset<FilterAtom>({});
const hasSelectedSuggestion = atom<boolean>(false);

export const searchAtoms = {
  searchString,
  selectedFilters,
  hasSelectedSuggestion,
};
