import React from 'react';

import {
  Badge,
  Chevron,
  FilterAccordionHeader,
  Title,
  IconWrapper,
  LeftContent,
  ExpandableContainer,
  Content,
} from './SearchFilterAccordion.styles';

import type { ISearchFilterAccordion } from './SearchFilterAccordion.types';

export const SearchFilterAccordion = (props: ISearchFilterAccordion) => {
  const {
    title,
    onBecameFocused,
    activeFiltersCount,
    hasGap,
    children,
    triggerButtonProps,
  } = props;

  return (
    <ExpandableContainer
      hasGap={hasGap}
      onBecameFocused={onBecameFocused}
      triggerButtonProps={triggerButtonProps}
      header={(focused, expanded) => (
        <FilterAccordionHeader>
          <LeftContent>
            <Title isFocused={focused} isExpanded={expanded}>
              {title}
            </Title>
            {!!activeFiltersCount && <Badge>{activeFiltersCount}</Badge>}
          </LeftContent>
          <IconWrapper isExpanded={expanded}>
            <Chevron isFocused={focused} isExpanded={expanded} />
          </IconWrapper>
        </FilterAccordionHeader>
      )}
    >
      <Content>{children}</Content>
    </ExpandableContainer>
  );
};
