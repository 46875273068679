import { SpaceBarIcon } from '~/assets/icons/SpaceBarIcon';
import { BackspaceIcon } from '~/assets/icons/BackspaceIcon';
import { KeyboardHorizontalLayoutMap } from '../../KeyboardHorizontal.types';
import {
  ALPHANUMERIC_SYMBOL,
  SPECIAL_SYMBOL,
  DIACRITICAL_SYMBOL,
} from '~/components/KeyboardTemplate/buildKeysRow';
import type { KeyboardTemplate } from '~/components/KeyboardTemplate/KeyboardTemplate.types';

type GenerateDefaultKeys = (param: {
  keyboardLayout: KeyboardHorizontalLayoutMap;
  toggleSpecialLayout: () => void;
  toggleDiacriticalLayout: () => void;
  handleSpace: () => void;
  handleBackspace: () => void;
}) => KeyboardTemplate;

export const generateDefaultKeys: GenerateDefaultKeys = (params) => {
  const {
    keyboardLayout,
    toggleSpecialLayout,
    toggleDiacriticalLayout,
    handleSpace,
    handleBackspace,
  } = params;

  return [
    [
      {
        symbol:
          keyboardLayout === KeyboardHorizontalLayoutMap.Special
            ? ALPHANUMERIC_SYMBOL
            : SPECIAL_SYMBOL,
        columns: 2,
        customAction: toggleSpecialLayout,
      },
      {
        symbol:
          keyboardLayout === KeyboardHorizontalLayoutMap.Diacritical
            ? ALPHANUMERIC_SYMBOL
            : DIACRITICAL_SYMBOL,
        columns: 2,
        customAction: toggleDiacriticalLayout,
      },
      {
        symbol: SpaceBarIcon,
        columns: 3,
        customAction: handleSpace,
      },
      {
        symbol: BackspaceIcon,
        columns: 3,
        customAction: handleBackspace,
      },
    ],
  ];
};
