import styled from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';

export const ResultsNotFoundContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ResultsNotFoundImage = styled.Image`
  width: ${horizontalScale(140)}px;
  height: ${verticalScale(72)}px;
  margin-bottom: ${verticalScale(24)}px;
`;

export const ResultsNotFoundTitle = styled.Text`
  font-size: ${verticalScale(28)}px;
  color: ${({ theme }) => theme.colors.text.default};
  text-align: center;
  margin-bottom: ${verticalScale(16)}px;
`;

export const ResultsNotFoundText = styled.Text`
  font-size: ${verticalScale(24)}px;
  color: ${({ theme }) => theme.colors.text.default};
  text-align: center;
  margin-bottom: ${verticalScale(24)}px;
  width: 50%;
`;

export const ClearAllFiltersText = styled.Text`
  font-size: ${verticalScale(18)}px;
`;
