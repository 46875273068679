import styled, { css } from 'styled-components/native';

import { horizontalScale } from '~/utils/layout';

export const Divider = styled.View<{ hasLinearGradient?: boolean }>`
  width: ${horizontalScale(1)}px;
  height: ${horizontalScale(32)}px;
  background-color: ${({ theme }) => theme.colors.search.divider};
  margin: 0px ${horizontalScale(20)}px;
  flex-shrink: 0;
  ${({ hasLinearGradient }) => {
    return css`
      margin-right: ${horizontalScale(hasLinearGradient ? 10 : 20)}px;
    `;
  }};
`;
