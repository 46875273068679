import React, { useState, useRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated } from 'react-native';
import { LayoutObject } from '@noriginmedia/react-spatial-navigation';

import fallbackCard from '~/assets/img/fallbackCard.png';
import { baseAssetsUrl } from '~/configs/env';
import { LockIcon } from '~/assets/icons/LockIcon';
import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';

import { CardImage } from './components/CardImage';
import {
  StyledFocusableButton,
  CardBlocked,
  CardTag,
  Tag,
  TagText,
  FallbackCardTitle,
  WatchedClass,
  InstructorName,
  StyledLinearGradient,
  CardTagText,
} from './Card.styles';

import type { ICard } from './Card.types';

const TRANSITION_TIME = 150;

export const Card = memo<ICard>((props) => {
  const {
    blockFocusDirections,
    isContentBlocked,
    imageURL,
    onSelectCard,
    innerRef,
    timesWatched,
    onBecameFocused,
    name,
    tag,
    focusKey,
    hasTVPreferredFocus,
    dense,
    customWidth,
    lazyImage,
    instructor,
  } = props;

  const { t } = useTranslation();
  const scale = useRef(new Animated.Value(1)).current;
  const [showFallbackImage, setShowFallbackImage] = useState(false);
  const cardAction = isContentBlocked ? undefined : onSelectCard;
  const cardProps = useBlockFocusDirection(blockFocusDirections || []);

  const innerOnBecameFocused = (element: LayoutObject) => {
    onBecameFocused?.(element);
    Animated.timing(scale, {
      toValue: 1.1,
      duration: TRANSITION_TIME,
      useNativeDriver: true,
    }).start();
  };

  const onBecameBlurred = () => {
    Animated.timing(scale, {
      toValue: 1,
      duration: TRANSITION_TIME,
      useNativeDriver: true,
    }).start();
  };

  return (
    <Animated.View style={{ transform: [{ scale }] }}>
      <StyledFocusableButton
        focusKey={focusKey}
        hasTVPreferredFocus={hasTVPreferredFocus}
        onPress={cardAction}
        onEnterPress={cardAction}
        onBecameFocused={innerOnBecameFocused}
        onBecameBlurred={onBecameBlurred}
        innerRef={innerRef}
        {...cardProps}
      >
        {tag && tag?.length > 0 && (
          <CardTag>
            <CardTagText>{t('search.new', 'Novo')}</CardTagText>
          </CardTag>
        )}

        <CardImage
          source={
            showFallbackImage
              ? (fallbackCard as any)
              : { uri: `${baseAssetsUrl}/${imageURL}` }
          }
          dense={dense}
          customWidth={customWidth}
          isLazy={lazyImage}
          onError={() => setShowFallbackImage(true)}
        >
          {showFallbackImage && name && (
            <FallbackCardTitle>{name}</FallbackCardTitle>
          )}
        </CardImage>

        {isContentBlocked && (
          <CardBlocked>
            <LockIcon />
          </CardBlocked>
        )}

        {!isContentBlocked && !!timesWatched && (
          <>
            <Tag>
              <TagText>
                {t('program.watched', 'Visto')} {timesWatched}{' '}
                {timesWatched > 1
                  ? t('program.times', 'vezes')
                  : t('program.time', 'vez')}
              </TagText>
            </Tag>
            <WatchedClass />
          </>
        )}

        {instructor && (
          <>
            <StyledLinearGradient />
            <InstructorName>{instructor}</InstructorName>
          </>
        )}
      </StyledFocusableButton>
    </Animated.View>
  );
});
