import type {
  Query,
  SearchSuggestions,
} from '~/services/searchPrograms/searchProgramsApi.types';

export const formatSuggestions = (suggestions: SearchSuggestions): Query[] => {
  return suggestions.map((suggestion, index) => ({
    id: index + 1,
    query: suggestion,
  }));
};
