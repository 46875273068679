import { ShiftIcon } from '~/assets/icons/ShiftIcon';
import { buildKeysRow } from '../../../KeyboardTemplate/buildKeysRow';

import type {
  KeyboardTemplate,
  LetterCase,
} from '../../../KeyboardTemplate/KeyboardTemplate.types';

const NUMBERS = '1234567890';
const LETTERS_1 = 'qwertyuiop';
const LETTERS_2 = 'asdfghjkl-';
const LETTERS_3 = 'zxcvbnm_';

type GenerateAlphanumericKeys = (param: {
  letterCase: LetterCase;
  toggleCase: () => void;
}) => KeyboardTemplate;

export const generateAlphanumericKeys: GenerateAlphanumericKeys = (params) => {
  const { letterCase, toggleCase } = params;

  return [
    buildKeysRow(NUMBERS, letterCase),
    buildKeysRow(LETTERS_1, letterCase),
    buildKeysRow(LETTERS_2, letterCase),
    [
      {
        symbol: ShiftIcon,
        columns: 2,
        customAction: toggleCase,
        selected: letterCase === 'upper',
      },
      ...buildKeysRow(LETTERS_3, letterCase),
    ],
  ];
};
