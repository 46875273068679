import styled from 'styled-components/native';

import { FocusableButton } from '~/components/FocusableButton/FocusableButton';
import { horizontalScale, verticalScale } from '~/utils/layout';

export const SearchResetButton = styled(FocusableButton).attrs(
  ({ theme: { colors } }) => ({
    textStyle: { fontSize: verticalScale(20) },
    bgFocusedColor: colors.search.itemBackground,
    borderColor: colors.search.defaultText,
    focusedBorderColor: colors.search.itemBackground,
  })
)`
  padding: ${verticalScale(4)}px ${horizontalScale(12)}px;
  border-width: 0px;
  border-radius: 4px;
  margin-right: ${horizontalScale(30)}px;
`;
