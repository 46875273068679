import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native';

import { fetchFilters } from '~/services/searchPrograms/searchProgramsApi';
import { Crashlytics } from '~/utils/crashlytics';
import type { FilterCategories } from '~/services/searchPrograms/searchProgramsApi.types';
import { formatFilterCategories } from '../../utils/formatFilterCategories';
import { useSearchFilters } from '../../hooks/useSearchFilters';
import { useScrollPostion } from '~/hooks/useScrollPostion';
import { VERTICAL_SAFE_ZONE } from '~/utils/constants';
import { horizontalScale } from '~/utils/layout';
import { SidebarFilterList } from '../SidebarFilterList/SidebarFilterList';
import { SidebarKeyboard } from '../SidebarKeyboard/SidebarKeyboard';
import { inMemoryCache } from '../../utils/inMemoryCache';

import { Container } from './Sidebar.styles';

export const Sidebar = () => {
  const [filterCategories, setFilterCategories] = useState<FilterCategories[]>(
    inMemoryCache.get('FILTERS') || []
  );

  const { activeFiltersCount, selectedFilters, onPressOption } =
    useSearchFilters();

  const { scrollToTop, handleElementFocus, scrollProps, scrollRef } =
    useScrollPostion<FlatList<FilterCategories>>();

  const getSegmentsFilter = async () => {
    if (inMemoryCache.get('FILTERS')) {
      return;
    }
    try {
      const segmentsOptions = await fetchFilters();
      const formattedCategories = formatFilterCategories(segmentsOptions);
      inMemoryCache.set('FILTERS', formattedCategories);
      setFilterCategories(formattedCategories);
    } catch (err) {
      Crashlytics.handleException(err, 'Search page get segment filters');
    }
  };

  useEffect(() => {
    getSegmentsFilter();
  }, []);

  return (
    <Container>
      <SidebarFilterList
        {...scrollProps}
        innerRef={scrollRef}
        filterCategories={filterCategories}
        listHeaderComponent={<SidebarKeyboard onBecameFocused={scrollToTop} />}
        selectedFilters={selectedFilters}
        activeFiltersCount={activeFiltersCount}
        onPressOption={onPressOption}
        handleElementFocus={handleElementFocus}
        contentContainerStyle={{
          paddingVertical: VERTICAL_SAFE_ZONE,
          paddingLeft: horizontalScale(16),
          paddingRight: horizontalScale(16),
        }}
      />
    </Container>
  );
};
