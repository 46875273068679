import React, { memo, useMemo } from 'react';

import { KeyboardTemplate } from '~/components/KeyboardTemplate/KeyboardTemplate';

import { generateSpecialKeys } from './generateSpecialKeys';

import type { IKeyboardHorizontalLayout } from '../../KeyboardHorizontal.types';

const SpecialLayoutComponent = (props: IKeyboardHorizontalLayout) => {
  const template = useMemo(() => generateSpecialKeys(), []);

  return <KeyboardTemplate {...props} template={template} hasGap />;
};

export const SpecialLayout = memo(SpecialLayoutComponent);
