import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { SVGIconProps } from '../icons.types';

export const CloseIcon = (props: SVGIconProps) => {
  const { colors } = useTheme();
  const {
    width = horizontalScale(12),
    height = verticalScale(12),
    fill = colors.text.default,
  } = props;

  return (
    <Svg width={width} height={height} viewBox="0 0 12 12">
      <Path
        d="M5.64518 6.81258L1.56185 10.8959C1.40907 11.0487 1.21463 11.1251 0.978516 11.1251C0.742405 11.1251 0.54796 11.0487 0.395182 10.8959C0.242405 10.7431 0.166016 10.5487 0.166016 10.3126C0.166016 10.0765 0.242405 9.88203 0.395182 9.72925L4.47852 5.64592L0.395182 1.56258C0.242405 1.4098 0.166016 1.21536 0.166016 0.979248C0.166016 0.743137 0.242405 0.548693 0.395182 0.395915C0.54796 0.243137 0.742405 0.166748 0.978516 0.166748C1.21463 0.166748 1.40907 0.243137 1.56185 0.395915L5.64518 4.47925L9.72852 0.395915C9.88129 0.243137 10.0757 0.166748 10.3118 0.166748C10.548 0.166748 10.7424 0.243137 10.8952 0.395915C11.048 0.548693 11.1243 0.743137 11.1243 0.979248C11.1243 1.21536 11.048 1.4098 10.8952 1.56258L6.81185 5.64592L10.8952 9.72925C11.048 9.88203 11.1243 10.0765 11.1243 10.3126C11.1243 10.5487 11.048 10.7431 10.8952 10.8959C10.7424 11.0487 10.548 11.1251 10.3118 11.1251C10.0757 11.1251 9.88129 11.0487 9.72852 10.8959L5.64518 6.81258Z"
        fill={fill}
      />
    </Svg>
  );
};
