import React, { memo, useMemo } from 'react';

import { KeyboardTemplate } from '~/components/KeyboardTemplate/KeyboardTemplate';
import { IKeyboardVerticalLayout } from '../../KeyboardVertical.types';

import { generateSpecialKeys } from './generateSpecialKeys';

const SpecialLayoutComponent = (props: IKeyboardVerticalLayout) => {
  const template = useMemo(() => generateSpecialKeys(), []);

  return <KeyboardTemplate {...props} template={template} hasGap />;
};

export const SpecialLayout = memo(SpecialLayoutComponent);
