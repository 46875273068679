import { buildKeysRow } from '~/components/KeyboardTemplate/buildKeysRow';
import type {
  KeyboardTemplate,
  LetterCase,
} from '~/components/KeyboardTemplate/KeyboardTemplate.types';

const NUMBERS = '123456';
const NUMBERS_2 = '7890àá';
const DIACRITICS_1 = 'âãäåæç';
const DIACRITICS_2 = 'èéêëìí';
const DIACRITICS_3 = 'îïñòóô';
const DIACRITICS_4 = 'õöøœ';
const DIACRITICS_5 = 'ù';

type GenerateDiacriticalKeys = (param: {
  letterCase: LetterCase;
}) => KeyboardTemplate;

export const generateDiacriticalKeys: GenerateDiacriticalKeys = (params) => {
  const { letterCase } = params;

  return [
    buildKeysRow(NUMBERS, letterCase),
    buildKeysRow(NUMBERS_2, letterCase),
    buildKeysRow(DIACRITICS_1, letterCase),
    buildKeysRow(DIACRITICS_2, letterCase),
    buildKeysRow(DIACRITICS_3, letterCase),
    [
      ...buildKeysRow(DIACRITICS_4, letterCase),
      { symbol: letterCase === 'upper' ? 'ẞ' : 'ß' },
      ...buildKeysRow(DIACRITICS_5, letterCase),
    ],
  ];
};
