import React from 'react';

import {
  CheckIcon,
  Content,
  FilterItem,
  Title,
  IconWrapper,
} from './SearchFilterItem.styles';

import type { ISearchFilterItem } from './SearchFilterItem.types';

export const SearchFilterItem = (props: ISearchFilterItem) => {
  const { title, onBecameFocused, isSelected, handlePress, hasGap, ...rest } =
    props;

  return (
    <FilterItem
      onBecameFocused={onBecameFocused}
      onEnterPress={handlePress}
      onPress={handlePress}
      hasGap={hasGap}
      {...rest}
    >
      {(focused) => (
        <>
          <Content>
            <Title isFocused={focused}>{title}</Title>
            {isSelected && (
              <IconWrapper>
                <CheckIcon />
              </IconWrapper>
            )}
          </Content>
        </>
      )}
    </FilterItem>
  );
};
