import React, { memo } from 'react';
import { useTheme } from 'styled-components/native';

import { useBlockFocusDirection } from '~/hooks/useBlockFocusDirection';

import { KeyboardKey, KeyRow } from './KeyboardTemplate.styles';

import type {
  IKeyboardTemplate,
  IKeyboardTemplateKey,
} from './KeyboardTemplate.types';

const KeyboardTemplateComponent = (props: IKeyboardTemplate) => {
  const {
    template,
    onChangeText,
    hasGap,
    keyboardColumns,
    keyHeight,
    keyWidth,
    firstKeyRef,
    focusKey,
    blockFocusUp,
    onBecameFocused,
  } = props;

  const { colors } = useTheme();

  const firstRowProps = useBlockFocusDirection('up');

  const onKeyPress = (item: IKeyboardTemplateKey) => {
    if (item.customAction) {
      item.customAction();
      return;
    }

    if (typeof item.symbol === 'string') {
      onChangeText((text) => text + item.symbol);
    }
  };

  const handleKeyIcon = (focused: boolean, item: IKeyboardTemplateKey) => {
    const Icon = typeof item.symbol === 'string' ? undefined : item.symbol;

    if (!Icon) {
      return null;
    }

    return (
      <Icon
        fill={
          focused ? colors.keyboard.keyTextFocused : colors.keyboard.keyText
        }
      />
    );
  };

  return (
    <>
      {template.map((row, rowIndex) => (
        <KeyRow
          key={rowIndex}
          hasGap={template.length - 1 !== rowIndex || hasGap}
          keyboardColumns={keyboardColumns}
          keyWidth={keyWidth}
        >
          {row.map((item, itemIndex) => {
            const isFirstRow = rowIndex === 0;
            const isFirstKey = itemIndex === 0 && isFirstRow;

            return (
              <KeyboardKey
                key={`${rowIndex}-${itemIndex}`}
                innerRef={isFirstKey ? firstKeyRef : undefined}
                focusKey={isFirstKey ? focusKey : undefined}
                text={typeof item.symbol === 'string' ? item.symbol : undefined}
                icon={(focused) => handleKeyIcon(focused, item)}
                onPress={() => onKeyPress(item)}
                onEnterPress={() => onKeyPress(item)}
                hasGap={row.length - 1 !== itemIndex}
                columns={item.columns}
                selected={item.selected}
                keyHeight={keyHeight}
                onBecameFocused={onBecameFocused}
                keyWidth={keyWidth}
                {...(blockFocusUp && isFirstRow ? firstRowProps : {})}
              />
            );
          })}
        </KeyRow>
      ))}
    </>
  );
};

export const KeyboardTemplate = memo(KeyboardTemplateComponent);
