import React, { useState, memo, useMemo, useCallback } from 'react';

import { KeyboardTemplate } from '~/components/KeyboardTemplate/KeyboardTemplate';
import { LetterCase } from '~/components/KeyboardTemplate/KeyboardTemplate.types';

import { generateDiacriticalKeys } from './generateDiacriticalKeys';

import type { IKeyboardHorizontalLayout } from '../../KeyboardHorizontal.types';

const DiacriticalLayoutComponent = (props: IKeyboardHorizontalLayout) => {
  const [letterCase, setLetterCase] = useState<LetterCase>('lower');

  const toggleCase = useCallback(() => {
    setLetterCase((state) => {
      if (state === 'lower') {
        return 'upper';
      }
      return 'lower';
    });
  }, []);

  const template = useMemo(
    () => generateDiacriticalKeys({ letterCase, toggleCase }),
    [letterCase, toggleCase]
  );

  return <KeyboardTemplate {...props} template={template} hasGap />;
};

export const DiacriticalLayout = memo(DiacriticalLayoutComponent);
