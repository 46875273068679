import React, { useCallback, useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useAtomValue } from 'jotai';

import type { IGridCardListItem } from '~/components/GridCardList/GridCardList.types';
import { Crashlytics } from '~/utils/crashlytics';
import type { SideMenuNavigationProp } from '~/routes/routes.types';
import { fetchSearchProgramsByFilters } from '~/services/searchPrograms/searchProgramsApi';
import { useSession } from '~/contexts/SessionContext/SessionContext';
import { searchAtoms } from '../../atoms/searchAtoms';
import { formatProgramCard } from '../../utils/formatProgramCard';
import { formatToSearchParams } from '../../utils/formatToSearchParams';
import { SearchGrid } from '../SearchGrid/SearchGrid';

export const SearchContent = () => {
  const { currentUser } = useSession();
  const navigation = useNavigation<SideMenuNavigationProp<'Search'>>();
  const searchString = useAtomValue(searchAtoms.searchString);
  const selectedFilters = useAtomValue(searchAtoms.selectedFilters);

  const [content, setContent] = useState({
    isLoading: true,
    programs: [] as IGridCardListItem[],
    visiblePrograms: [] as IGridCardListItem[],
  });

  const getProgramsByFilter = useCallback(
    async (searchParams: string | undefined, signal: AbortSignal) => {
      const userLanguage =
        currentUser?.preferences?.language === 'pt-br' ? 'pt-br' : 'es-mx';
      try {
        setContent((oldState) => ({
          ...oldState,
          isLoading: true,
        }));
        const programsResult = await fetchSearchProgramsByFilters({
          locale: userLanguage,
          searchParams,
          signal,
        });
        const programCards = formatProgramCard(
          programsResult,
          navigation.navigate
        );

        setContent({
          isLoading: false,
          programs: programCards,
          visiblePrograms: programCards.slice(0, 15),
        });
      } catch (err) {
        if (err?.name !== 'RequestCanceled') {
          setContent((oldState) => ({
            ...oldState,
            isLoading: false,
          }));
          Crashlytics.handleException(err, 'Search page filters');
        }
      }
    },
    [navigation, currentUser]
  );

  useEffect(() => {
    const controller = new AbortController();

    const searchParams = formatToSearchParams(searchString, selectedFilters);

    const timer = setTimeout(
      () => getProgramsByFilter(searchParams, controller.signal),
      1000
    );

    return () => {
      controller.abort();
      clearTimeout(timer);
    };
  }, [navigation, searchString, selectedFilters, getProgramsByFilter]);

  const handleLoadMore = useCallback(() => {
    if (content.visiblePrograms.length < content.programs.length) {
      const nextPrograms = content.programs.slice(
        content.visiblePrograms.length,
        content.visiblePrograms.length + 6
      );
      setContent((prev) => ({
        ...prev,
        visiblePrograms: [...prev.visiblePrograms, ...nextPrograms],
      }));
    }
  }, [content]);

  return (
    <SearchGrid
      handleLoadMore={handleLoadMore}
      visiblePrograms={content.visiblePrograms}
      isLoading={content.isLoading}
    />
  );
};
