import styled from 'styled-components/native';

import { horizontalScale, verticalScale } from '~/utils/layout';
import { FocusableButton } from '../FocusableButton/FocusableButton';

import { IFilterBadgeProps } from './FilterBadge.types';

export const Container = styled(FocusableButton).attrs(
  ({ theme: { colors } }) => ({
    textStyle: { fontSize: verticalScale(18) },
    bgFocusedColor: colors.filterBadge.focusedBg,
    borderColor: colors.filterBadge.defaultText,
    focusedBorderColor: colors.filterBadge.focusedBg,
  })
)<IFilterBadgeProps>`
  flex-direction: row;
  margin-right: ${({ hasGap }) => horizontalScale(hasGap ? 16 : 0)}px;
  padding: ${horizontalScale(4)}px ${horizontalScale(12)}px;
  border-width: ${verticalScale(2)}px;
  border-radius: ${verticalScale(50)}px;
`;

export const Content = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const IconContainer = styled.View<IFilterBadgeProps>`
  margin-right: ${({ isLeft }) => (isLeft ? horizontalScale(8) : 0)}px;
  margin-left: ${({ isLeft }) => (!isLeft ? horizontalScale(8) : 0)}px;
`;

export const Label = styled.Text<IFilterBadgeProps>`
  color: ${({ isFocused, theme }) =>
    isFocused
      ? theme.colors.filterBadge.focusedText
      : theme.colors.filterBadge.defaultText};

  font-size: ${verticalScale(20)}px;
  margin-bottom: ${verticalScale(2)}px;
`;
