import type { IGridCardListItem } from '~/components/GridCardList/GridCardList.types';
import type { ISearchProgram } from '~/services/searchPrograms/searchProgramsApi.types';

export const formatProgramCard = (
  programs: ISearchProgram[],
  navigate: (screen: string, params: Record<string, any>) => void
): IGridCardListItem[] => {
  return programs.map(
    (program) =>
      ({
        id: program.id,
        name: program.name,
        imageURL: program.imageCard,
        instructor: program.details?.instructors?.[0]?.name,
        tag: program.tag || '',
        onSelectCard: () => {
          navigate('Program', { id: program.id });
        },
      } as IGridCardListItem)
  );
};
