import React, { useCallback } from 'react';
import { useTheme } from 'styled-components/native';

import type { SVGIconProps } from '~/assets/icons.types';

import { IconContainer, Label, Container, Content } from './FilterBadge.styles';

import type { IFilterBadge } from './FilterBadge.types';

export const FilterBadge = (props: IFilterBadge) => {
  const {
    text,
    innerRef,
    leftIcon,
    rightIcon,
    handlePress,
    hasGap,
    hasTVPreferredFocus,
    onBecameFocused,
    ...blockProps
  } = props;

  const { colors } = useTheme();

  const renderIcon = useCallback(
    (focused: boolean, Icon: (props: SVGIconProps) => JSX.Element) => {
      return (
        <Icon
          fill={
            focused
              ? colors.filterBadge.focusedText
              : colors.filterBadge.defaultText
          }
        />
      );
    },
    [colors]
  );

  return (
    <Container
      innerRef={innerRef}
      onPress={handlePress}
      onEnterPress={handlePress}
      hasGap={hasGap}
      hasTVPreferredFocus={hasTVPreferredFocus}
      onBecameFocused={onBecameFocused}
      {...blockProps}
    >
      {(focused) => (
        <Content>
          {leftIcon && (
            <IconContainer isLeft={!!leftIcon}>
              {renderIcon(focused, leftIcon)}
            </IconContainer>
          )}
          <Label isFocused={focused}>{text}</Label>
          {rightIcon && (
            <IconContainer>{renderIcon(focused, rightIcon)}</IconContainer>
          )}
        </Content>
      )}
    </Container>
  );
};
