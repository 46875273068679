import { useTranslation } from 'react-i18next';
import React from 'react';
import { ImageSourcePropType } from 'react-native';

import resultNotFoundImage from '~/assets/img/emptySearch.png';

import {
  ResultsNotFoundContainer,
  ResultsNotFoundImage,
  ResultsNotFoundText,
  ResultsNotFoundTitle,
} from './ResultsNotFound.styles';

export const ResultsNotFound = () => {
  const { t } = useTranslation();

  return (
    <ResultsNotFoundContainer>
      <ResultsNotFoundImage
        source={resultNotFoundImage as ImageSourcePropType}
      />
      <ResultsNotFoundTitle>
        {t('search.results_not_found_title', 'Ops, resultados não encontrados')}
      </ResultsNotFoundTitle>
      <ResultsNotFoundText>
        {t(
          'search.results_not_found_description',
          'Confira a ortografia, experimente outros termos ou ajuste os filtros. Você também pode recomeçar sua busca.'
        )}
      </ResultsNotFoundText>
    </ResultsNotFoundContainer>
  );
};
