import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { LetterCase } from '../KeyboardTemplate/KeyboardTemplate.types';
import { FocusableButtonRef } from '../FocusableButton/FocusableButton.types';
import { isNativeButton } from '../FocusableButton/FocusableButton';
import { Container } from '~/components/KeyboardTemplate/KeyboardTemplate.styles';
import { horizontalScale, verticalScale } from '~/utils/layout';

import { AlphanumericLayout } from './components/AlphanumericLayout/AlphanumericLayout';
import { TopControls } from './components/TopControls/TopControls';
import { BottomControls } from './components/BottomControls/BottomControls';
import {
  IKeyboardVertical,
  KeyboardVerticalLayoutMap,
} from './KeyboardVertical.types';
import { DiacriticalLayout } from './components/DiacriticalLayout/DiacriticalLayout';
import { SpecialLayout } from './components/SpecialLayout/SpecialLayout';
import { EmailControls } from './components/EmailControls/EmailControls';

export const KEYBOARD_COLUMNS = 6;
export const KEY_HEIGHT = verticalScale(40);
export const KEY_WIDTH = horizontalScale(44);

const KeyboardVerticalComponent = (props: IKeyboardVertical) => {
  const {
    type = 'default',
    focusKey,
    onChangeText,
    hasTVPreferredFocus,
    onBecameFocused,
    blockFocusUp,
  } = props;
  const [letterCase, setLetterCase] = useState<LetterCase>('lower');

  const [keyboardLayout, setKeyboardLayout] =
    useState<KeyboardVerticalLayoutMap>(KeyboardVerticalLayoutMap.Alphanumeric);

  const firstKeyRef = useRef<FocusableButtonRef>(null);

  const toggleCase = useCallback(() => {
    setLetterCase((state) => {
      if (state === 'lower') {
        return 'upper';
      }
      return 'lower';
    });
  }, []);

  useLayoutEffect(() => {
    setKeyboardLayout(KeyboardVerticalLayoutMap.Alphanumeric);
  }, [type]);

  useEffect(() => {
    if (!firstKeyRef.current) {
      return;
    }

    if (isNativeButton(firstKeyRef.current) && hasTVPreferredFocus) {
      firstKeyRef.current.setNativeProps({
        hasTVPreferredFocus: true,
      });
    }
  }, [hasTVPreferredFocus]);

  return (
    <Container keyboardColumns={KEYBOARD_COLUMNS} keyWidth={KEY_WIDTH}>
      <TopControls
        onChangeText={onChangeText}
        focusKey={focusKey}
        letterCase={letterCase}
        toggleCase={toggleCase}
        keyboardColumns={KEYBOARD_COLUMNS}
        keyHeight={KEY_HEIGHT}
        keyWidth={KEY_WIDTH}
        onBecameFocused={onBecameFocused}
        blockFocusUp={blockFocusUp}
      />
      {keyboardLayout === KeyboardVerticalLayoutMap.Alphanumeric && (
        <AlphanumericLayout
          onChangeText={onChangeText}
          letterCase={letterCase}
          firstKeyRef={firstKeyRef}
          onBecameFocused={onBecameFocused}
          keyboardColumns={KEYBOARD_COLUMNS}
          keyHeight={KEY_HEIGHT}
          keyWidth={KEY_WIDTH}
        />
      )}

      {keyboardLayout === KeyboardVerticalLayoutMap.Diacritical && (
        <DiacriticalLayout
          onChangeText={onChangeText}
          letterCase={letterCase}
          keyboardColumns={KEYBOARD_COLUMNS}
          onBecameFocused={onBecameFocused}
          keyHeight={KEY_HEIGHT}
          keyWidth={KEY_WIDTH}
        />
      )}

      {keyboardLayout === KeyboardVerticalLayoutMap.Special && (
        <SpecialLayout
          onChangeText={onChangeText}
          keyboardColumns={KEYBOARD_COLUMNS}
          onBecameFocused={onBecameFocused}
          keyHeight={KEY_HEIGHT}
          keyWidth={KEY_WIDTH}
        />
      )}

      {type === 'email' ? (
        <EmailControls
          onChangeText={onChangeText}
          keyboardLayout={keyboardLayout}
          updateLayout={setKeyboardLayout}
          keyboardColumns={KEYBOARD_COLUMNS}
          onBecameFocused={onBecameFocused}
          keyHeight={KEY_HEIGHT}
          keyWidth={KEY_WIDTH}
        />
      ) : (
        <BottomControls
          onChangeText={onChangeText}
          letterCase={letterCase}
          keyboardLayout={keyboardLayout}
          updateLayout={setKeyboardLayout}
          keyboardColumns={KEYBOARD_COLUMNS}
          onBecameFocused={onBecameFocused}
          keyHeight={KEY_HEIGHT}
          keyWidth={KEY_WIDTH}
        />
      )}
    </Container>
  );
};

export const KeyboardVertical = memo(KeyboardVerticalComponent);
