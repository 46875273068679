import React, { memo, useCallback } from 'react';
import { FlatList, ListRenderItemInfo } from 'react-native';

import { FocusDirections } from '~/hooks/useBlockFocusDirection';
import { useSideMenuOnScreen } from '~/hooks/useSideMenuOnScreen';
import { verticalScale } from '~/utils/layout';
import { Card } from '../Card/Card';

import { CardContainer, Subtitle } from './GridCardList.styles';

import type { IGridCardList, IGridCardListItem } from './GridCardList.types';

const GridCardListComponent = (props: IGridCardList) => {
  const {
    isContentBlocked,
    isLoading,
    cards,
    onBecameFocused,
    columns,
    focusKey,
    hasTVPreferredFocus,
    customCardWidth,
    lazyContentLine,
    innerRef,
    contentContainerStyle,
    ListHeaderComponent,
    ListEmptyComponent,
    onLayout,
    columnSpacing = 40,
    onEndReached,
    onEndReachedThreshold,
  } = props;

  const { isSideMenuOnScreen } = useSideMenuOnScreen();

  const handleBlockFocusDirections = useCallback(() => {
    const blockRight: FocusDirections = ['right'];
    const blockDown: FocusDirections = ['down'];
    const blockDownRight: FocusDirections = ['down', 'right'];

    return (isLastCard: boolean, isLastLine: boolean) => {
      if (isLastCard && isLastLine) {
        return blockDownRight;
      } else if (isLastCard) {
        return blockRight;
      } else if (isLastLine) {
        return blockDown;
      }
    };
  }, []);

  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<IGridCardListItem>) => {
      const isLastCard = index === cards.length - 1;
      const isLastLine = cards.length - index <= columns;

      const getBlockFocusDirections = handleBlockFocusDirections();
      const blockFocusDirections = getBlockFocusDirections(
        isLastCard,
        isLastLine
      );

      return (
        <CardContainer
          index={index}
          columns={columns}
          columnSpacing={columnSpacing}
        >
          <Card
            customWidth={customCardWidth}
            dense={isSideMenuOnScreen}
            focusKey={index === 0 ? focusKey : undefined}
            hasTVPreferredFocus={index === 0 ? hasTVPreferredFocus : undefined}
            lazyImage={
              lazyContentLine ? index >= columns * lazyContentLine : false
            }
            isContentBlocked={isContentBlocked}
            imageURL={item.imageURL}
            onSelectCard={item.onSelectCard}
            onBecameFocused={onBecameFocused}
            timesWatched={item.timesWatched}
            name={item.name}
            tag={item.tag}
            instructor={item.instructor}
            blockFocusDirections={blockFocusDirections}
          />
          {!!item.subtitle && <Subtitle>{item.subtitle}</Subtitle>}
        </CardContainer>
      );
    },
    [
      cards.length,
      columns,
      customCardWidth,
      focusKey,
      hasTVPreferredFocus,
      isContentBlocked,
      isSideMenuOnScreen,
      lazyContentLine,
      onBecameFocused,
      columnSpacing,
      handleBlockFocusDirections,
    ]
  );

  return (
    <FlatList
      ref={innerRef}
      data={isLoading ? [] : cards}
      keyExtractor={keyExtractor}
      numColumns={columns}
      showsVerticalScrollIndicator={false}
      onLayout={onLayout}
      columnWrapperStyle={
        columns > 1 ? { marginBottom: verticalScale(columnSpacing) } : undefined
      }
      contentContainerStyle={[
        // eslint-disable-next-line react-native/no-inline-styles
        { flexGrow: 1 },
        contentContainerStyle,
      ]}
      ListHeaderComponent={ListHeaderComponent}
      ListEmptyComponent={ListEmptyComponent}
      renderItem={renderItem}
      onEndReached={onEndReached}
      onEndReachedThreshold={onEndReachedThreshold}
    />
  );
};

const keyExtractor = (item: IGridCardListItem) => item.id.toString();

export const GridCardList = memo(GridCardListComponent);
