import styled from 'styled-components/native';
import { FlatList } from 'react-native';

import { LinearGradient } from '~/components/LinearGradient/LinearGradient';
import { horizontalScale, verticalScale } from '~/utils/layout';
import { TimerIcon as Timer } from '~/assets/icons/TimerIcon';
import type { Query } from '~/services/searchPrograms/searchProgramsApi.types';

export const Container = styled.View`
  flex-direction: row;
  max-width: 100%;
  min-height: ${verticalScale(42)}px;
`;

export const Row = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const RecentAndPopularList = styled(
  FlatList as new () => FlatList<Query>
).attrs({
  horizontal: true,
  showsHorizontalScrollIndicator: false,
  contentContainerStyle: {
    paddingLeft: horizontalScale(8),
    paddingRight: horizontalScale(16),
    alignItems: 'center',
  },
})`
  position: relative;
`;

export const LinearGradientStart = styled(LinearGradient).attrs({
  colors: ['rgba(0, 0, 0, 1)', 'rgba(0, 0, 0, 0)'],
  start: { x: 2, y: 0.5 },
  end: { x: 2, y: 0.5 },
  direction: 'horizontal',
})`
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${horizontalScale(8)}px;
  z-index: 4;
`;

export const LinearGradientEnd = styled(LinearGradient).attrs({
  colors: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 1)'],
  start: { x: 2, y: 0.5 },
  end: { x: 2, y: 0.5 },
  direction: 'horizontal',
})`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${horizontalScale(20)}px;
  z-index: 4;
`;

export const TimerIcon = styled(Timer).attrs({
  width: horizontalScale(20),
  height: verticalScale(20),
})``;
