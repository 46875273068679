import styled, { css } from 'styled-components/native';

import { verticalScale, horizontalScale } from '~/utils/layout';
import { FocusableButton } from '../FocusableButton/FocusableButton';

import type {
  IKeyboardContainer,
  IKeyboardRow,
  IKeyboardKey,
} from './KeyboardTemplate.types';

export const KEYBOARD_GAP = verticalScale(4);
export const DEFAULT_WIDTH = horizontalScale(52);
export const DEFAULT_HEIGHT = verticalScale(44);

export const getKeyboardWidth = (keyboardColumns: number, keyWidth?: number) =>
  (keyWidth || DEFAULT_WIDTH) * keyboardColumns +
  KEYBOARD_GAP * (keyboardColumns - 1);

export const Container = styled.View<IKeyboardContainer>`
  flex-direction: row;
  flex-wrap: wrap;
  ${({ keyboardColumns, keyWidth }) => {
    if (keyboardColumns) {
      return css`
        width: ${getKeyboardWidth(
          keyboardColumns,
          keyWidth || DEFAULT_WIDTH
        )}px;
      `;
    }
  }}
`;

export const KeyRow = styled.View<IKeyboardRow>`
  flex-direction: row;
  margin-bottom: ${({ hasGap }) => (hasGap ? KEYBOARD_GAP : 0)}px;

  ${({ keyboardColumns, keyWidth }) => {
    if (keyboardColumns) {
      return css`
        width: ${getKeyboardWidth(
          keyboardColumns,
          keyWidth || DEFAULT_WIDTH
        )}px;
      `;
    }
  }}
`;

export const KeyboardKey = styled(FocusableButton).attrs<IKeyboardKey>(
  ({ theme: { colors } }) => ({
    textStyle: { fontSize: verticalScale(18) },
    textColor: colors.keyboard.keyText,
    focusedTextColor: colors.keyboard.keyTextFocused,
    borderColor: colors.keyboard.keyFocusedBg,
    bgColor: colors.keyboard.keyBackground,
    bgFocusedColor: colors.keyboard.keyFocusedBg,
  })
)<IKeyboardKey>`
  font-family: ${({ theme }) => theme.fonts.regular};
  margin-right: ${({ hasGap }) => (hasGap ? KEYBOARD_GAP : 0)}px;
  border-width: ${({ selected }) => (selected ? verticalScale(1) : 0)}px;
  border-radius: ${verticalScale(4)}px;
  height: ${({ keyHeight }) => keyHeight || DEFAULT_HEIGHT}px;
  ${({ columns = 1, keyWidth }) => {
    if (columns === 'auto') {
      return css`
        flex: 1;
        min-width: ${keyWidth || DEFAULT_WIDTH}px;
      `;
    }

    return css`
      width: ${columns * (keyWidth || DEFAULT_WIDTH) +
      (columns - 1) * KEYBOARD_GAP}px;
    `;
  }}
`;
