import React from 'react';

import { CardSkeleton, GridSkeleton } from './SearchGridSkeleton.styles';

import type { ISearchGridSkeleton } from './SearchGridSkeleton.types';

export const SearchGridSkeleton = (props: ISearchGridSkeleton) => {
  const { numberOfItems, columns } = props;
  return (
    <GridSkeleton>
      {new Array(numberOfItems).fill(0).map((_, index) => (
        <CardSkeleton key={index} columns={columns} itemIndex={index} />
      ))}
    </GridSkeleton>
  );
};
