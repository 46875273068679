import React, { memo } from 'react';
import { useAtomValue } from 'jotai';

import { SearchRecentAndPopular } from '../SearchRecentAndPopular/SearchRecentAndPopular';
import { SearchAutoComplete } from '../SearchAutoComplete/SearchAutoComplete';
import { searchAtoms } from '../../atoms/searchAtoms';
import { SearchWithFilters } from '../SearchWithFilters/SearchWithFilters';
import { useSearchFilters } from '../../hooks/useSearchFilters';

import { Container, FiltersContainer } from './SearchHeader.styles';

import type { ISearchHeader } from './SearchHeader.types';

export const SearchHeader = memo<ISearchHeader>((props) => {
  const { programsCount } = props;

  const { selectedFilterOptions } = useSearchFilters();
  const searchString = useAtomValue(searchAtoms.searchString);

  const showRecentSearches =
    selectedFilterOptions.length === 0 && searchString.length === 0;
  const showSearchWithFilters = selectedFilterOptions.length > 0;

  return (
    <Container>
      <FiltersContainer>
        {showRecentSearches && <SearchRecentAndPopular />}
        {showSearchWithFilters && <SearchWithFilters />}
        {!showRecentSearches && !showSearchWithFilters && (
          <SearchAutoComplete
            focusable={programsCount > 0 && searchString.length > 0}
          />
        )}
      </FiltersContainer>
    </Container>
  );
});
