import React, { memo, useMemo, useCallback } from 'react';

import { KeyboardTemplate } from '~/components/KeyboardTemplate/KeyboardTemplate';
import { IKeyboardVerticalTopControls } from '../../KeyboardVertical.types';

import { generateTopKeys } from './generateTopKeys';

const TopControlsComponent = (props: IKeyboardVerticalTopControls) => {
  const { onChangeText, letterCase, toggleCase } = props;

  const handleSpace = useCallback(() => {
    onChangeText((prevText) => prevText + ' ');
  }, [onChangeText]);

  const handleBackspace = useCallback(() => {
    onChangeText((prevText) => prevText.slice(0, -1));
  }, [onChangeText]);

  const template = useMemo(
    () =>
      generateTopKeys({
        letterCase,
        toggleCase,
        handleSpace,
        handleBackspace,
      }),
    [handleSpace, handleBackspace, letterCase, toggleCase]
  );

  return <KeyboardTemplate {...props} template={template} hasGap />;
};

export const TopControls = memo(TopControlsComponent);
