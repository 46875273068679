import { Dispatch, SetStateAction } from 'react';

import type {
  IKeyboard,
  IKeyboardTemplate,
} from '../KeyboardTemplate/KeyboardTemplate.types';

export interface IKeyboardHorizontal extends IKeyboard {
  type?: 'default' | 'email';
}

export enum KeyboardHorizontalLayoutMap {
  Alphanumeric = 'alphanumeric',
  Special = 'special',
  Diacritical = 'diacritical',
}

export interface IKeyboardHorizontalLayout
  extends Omit<IKeyboardTemplate, 'template' | 'hasGap'> {}

export interface IKeyboardControls extends IKeyboardHorizontalLayout {
  updateLayout: Dispatch<SetStateAction<KeyboardHorizontalLayoutMap>>;
  keyboardLayout: KeyboardHorizontalLayoutMap;
}
