import styled from 'styled-components/native';

import {
  CARD_DIMENSIONS,
  SEARCH_CARD_WIDTH,
  HORIZONTAL_SAFE_ZONE,
} from '~/utils/constants';
import { horizontalScale, verticalScale } from '~/utils/layout';

export const GridSkeleton = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  top: ${verticalScale(125)}px;
  left: 0;
  right: 0;
  padding-left: ${horizontalScale(48)}px;
  padding-right: ${HORIZONTAL_SAFE_ZONE}px;
  z-index: 10;
`;

export const CardSkeleton = styled.View<{ itemIndex: number; columns: number }>`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.itemBackground};
  margin-right: ${({ itemIndex, columns }) =>
    (itemIndex + 1) % columns === 0 ? 0 : horizontalScale(32)}px;
  margin-bottom: ${horizontalScale(32)}px;
  flex-basis: ${SEARCH_CARD_WIDTH}px;
  height: ${SEARCH_CARD_WIDTH * CARD_DIMENSIONS.aspectRatio}px;
  border-radius: ${horizontalScale(8)}px;
`;
